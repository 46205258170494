import React, { useEffect, useState } from 'react';
import axios, { formToJSON } from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { hide, bikinInfoSum, bikinInfoSumUMDNew, sumDataKerja, getFrozen, createRatioZoom, getRatioZoom, saveheighrow, getheighrow, bikinselect } from '../helper/hide';
import { ToastContainer, toast } from 'react-toastify';
import { dataconfig, showtoolbarConfig, ct } from '../helper/luckysheetHelper';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './modal';
import Modalpreview from './modalpreview';
import ModalFilter from './modalFilter';
import Loading from './Ld';

const tipedata = "DATACONT";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostdatcogitnt = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const ADMIN = "admin"
const POL = "pol"
const CNEE = "cnee"
const SALES = "sales"
const KONSEPTOR = "koseptor"
const TIPE_TRANS = "tipe_trans"
const MARKING = "marking"
const PORT = "port"
const STATUS_BL = "status_bl"
const UK = "uk"
const PPN_BR = "ppn_br"
const SENDBONGKARAN = "send_bongkaran"

const notify_cant_update_br_cont = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1200,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};

const DATACONT = () => {
  const [isLoading, setIsLoading] = useState(false);
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "DATACONTCOLUMN"
  const pagerow = "FCLROW"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  var type_row = "";
  var type_jml = 0;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  var end_column_freze = 10;
  var size_scroll_left = 0;
  var defaultRowHeight = 30;
  var defaultratio = 1;

  const [role, setRole] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalFilterVisible, setModalFilterVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);
  const [title, setTitle] = useState('DATA CONTAINER');
  const [historyfilter, setHistoryfilter] = useState("");
  const [titlefilter, setTitleFilter] = useState("");
  const [fikiArray, setFikiArray] = useState([]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleFilterModal = () => {
    setModalFilterVisible(!modalFilterVisible);
  };

  function getDataFilterTersimpan() {
    let parsedData = [];
    var titlefilter = getTitleFilter()
    const fullURL = window.location.href;
    const nameFrozen = "filtersave-" + fullURL;
    const retrievedData = localStorage.getItem(nameFrozen);

    if (retrievedData) {
      parsedData = JSON.parse(retrievedData);
    }

    if (Array.isArray(parsedData)) {
      // cari index sesuai pencarian fki
      const index = parsedData.findIndex(item => Object.keys(item)[0] === titlefilter);
      if (index !== -1) {
        const fikiArray = parsedData[index][titlefilter];
        //console.log("fikiArray ", fikiArray);
        setFikiArray(fikiArray);
        return fikiArray;
      } else {
        //console.log("Data not found");
      }
    } else {
      //console.log("Invalid or empty data");
    }
    return [];
  }

  function callFunctionlocalstorage() {
    const frozenPosition = getFrozen();
    end_column_freze = frozenPosition;

    const ratiozoom = getRatioZoom();
    defaultratio = ratiozoom;
    const h_c = getheighrow();
    defaultRowHeight = h_c;

    var nama = getTitleFilter()
    setTitleFilter(nama);
    getDataFilterTersimpan();
  }

  function getTitleFilter() {
    const fullURL = window.location.href;
    const nameFrozen = "filtersaveTitle-" + fullURL;
    const retrievedData = localStorage.getItem(nameFrozen);
    return retrievedData;
  }

  useEffect(() => {
    callFunctionlocalstorage();
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
        initializeLuckySheet(newToken);
      }
    };

    const initializeLuckySheet = async (token) => {
      new Promise((resolve) => setTimeout(resolve, 100));
      const config = apiConfig(token);

      setIsLoading(true);
      const getColumnNames = async () => {
        try {
          const config = apiConfig(token);
          const response = await axios.post(`${hostcont}ReactBrcontainerColumn`, null, config);
          return response.data.column;
        } catch (error) {
          console.error('Error fetching column names:', error);
          return [];
        }
      };

      const getCellData = async (data, savedColumnWidths, urutanload) => {
        let fh = getDataFilterTersimpan();
       // console.log("FH : ",fh);
        try {
          const response = await axios.post(`${hostcont}ReactBrcontainers`, { data, savedColumnWidths, urutanload, fh }, config);
          return response;
        } catch (error) {
          console.error('Error fetching cell data:', error);
          return [];
        }
      };

      const nama_column = await getColumnNames();
      //console.log("Nama column ", nama_column)
      const data_pilihan = ["harga_custom", "harga_kapal", "lainnya", "jual", "pib", "pph_bebas", "notul", "dana_kerja", "biaya_do", "biaya_lainnya", "demmurage", "jaminan_overpayment", "sisa_jaminan", "total_biaya_do", "pendo_c", "penarikkan", "handling_kbkb", "bc", "tila_muat", "gerakkan", "storages", "ppjk", "lainnya_bh", "total_biaya_lapangan", "biaya_trucking", "lolo", "bongkar", "parkir", "repo", "penitipan_inap", "etc", "total_trucking", "lainnya_bw", "kapal", "form_e", "ls", "daop", "biaya_kerja", "sisa_dana_kerja", "sewa_pt", "jasa_kerja", "biaya_kerja_daop_pib", "Total_biaya_tanpa_kapal", "asuransi", "biaya_pelabuhan", "do_lapangan_transport_fe_ls_bc", "pib_biaya", "total_biaya", "dk_total_handling", "dk_bayar_lapangan", "dk_cross_check", "dk_selisih_hitungan", "dk_total_biaya", "dk_sisa_dana", "dk_profit", "daop_jasa", "daop_penarikkan", "daop_kuli_cntr", "daop_pemeriksaan_bagasi", "daop_koord_periksa", "daop_staff_periksa", "daop_p2_bawah", "daop_p2_bawah_wilayah", "daop_analis_p2_bawah", "daop_pfpd", "daop_pusat", "daop_wilayah_atas", "daop_pelayanan_atas", "daop_penyidik", "daop_ops_bahendle", "daop_mdn", "koordinasi_lap", "uang_bongkar", "repair", "trucking_j3", "bahendel", "aju_pib_pl", "aju_zb", "aju_ops_pel","pemakaian_op","daop_colok","biaya_sewa_gudang","biaya_kuota","inv_ls_anindya","kawalan_pfpd","koordinasi_p2","daop_notul","hico_scan"];
      let datahasil = [];
      const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
      for (let i = 0; i < cariindex.length; i++) {
        const columnNumber = cariindex[i];
        const columnPosition = getColumnPosition(columnNumber);
        datahasil.push(columnPosition)
      }

      let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
      if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
        savedColumnWidths = null;
      } else {
        savedColumnWidths = savedColumnWidths;
      }

      let cellDataJsonA = [];
      let totaldata = 0;
      let totaldataasli = 0;

      function isObjectEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      }

      async function loaddata(offset) {
        cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset);
        if (cellDataJsonA.length === 0) {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        } else {
          if (isObjectEmpty(cellDataJsonA.data)) {
            return {
              "data": [{}],
              "dataformula": [{}]
            };
          } else {
            totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
            totaldataasli = totaldataasli + cellDataJsonA.data.totaldata;
            for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
              if (cellDataJsonA.data.data[i].v.ct == "0") {//title
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "@",
                  "t": "s",
                };
                cellDataJsonA.data.data[i].v.bl = 1;
                cellDataJsonA.data.data[i].v.ff = 1;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
                //cellDataJsonA.data.data[i].v.fc ="#8c8c8c"
              } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "#,##0",
                  "t": "n",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 2;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "yyyy-MM-dd",
                  "t": "d",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "@",
                  "t": "l",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else if (cellDataJsonA.data.data[i].v.ct == "4") {//text center
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "0",
                  "t": "n",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else {
                cellDataJsonA.data.data[i].v.ct = {//text
                  "fa": "@",
                  "t": "n",

                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 1;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              }
            }
          }
          const data_all = cellDataJsonA.data;
          return data_all;
        }
      }

      const [page0Data] = await Promise.all([
        loaddata(0),
      ]);

      const page0 = page0Data || [];

      const merged = [
        ...page0.data,
      ];

      const mergedformula = [
        ...page0.dataformula,
      ];

      const dk_selisih_hitungan_ = nama_column.indexOf("dk_selisih_hitungan")
      const dk_selisih_hitungan = getColumnPosition(dk_selisih_hitungan_);
      const dk_selisih_hitungan_read = dk_selisih_hitungan + '0:' + dk_selisih_hitungan + '1';

      const dk_tgl_bayar_ = nama_column.indexOf("dk_tgl_bayar_kembali")
      const dk_tgl_bayar = getColumnPosition(dk_tgl_bayar_);

      const dk_cross_check_ = nama_column.indexOf("dk_cross_check")
      const dk_cross_check = getColumnPosition(dk_cross_check_);
      const dk_tgl_bayar_read = dk_cross_check + '0:' + dk_cross_check + '1';

      const dk_bayar_lapangan_ = nama_column.indexOf("dk_bayar_lapangan")
      const dk_bayar_lapangan = getColumnPosition(dk_bayar_lapangan_);
      const dk_bayar_lapangan_read = dk_bayar_lapangan + '0:' + dk_bayar_lapangan + '1';

      const mergedDataRO = page0.readonly + "," + dk_selisih_hitungan_read + "," + dk_tgl_bayar_read + "," + dk_bayar_lapangan_read;
      //console.log(mergedDataRO);

      const dataformula = await loaddata(0);
      const canformual = page0.dataformula;
      var testdata;
      options = {
        container: "luckysheet",
        title: "DataCont",
        lang: 'en',
        showinfobar: false,
        allowCopy: true,
        allowEdit: true,
        allowpaste:true,
        column: 0,
        // filter: {
        //   ref: 'A1:C1',
        // },
        forceCalculation: true,
        showtoolbarConfig: dataconfig,
        cellRightClickConfig: showtoolbarConfig,
        data: [
          {
            name: "Sheet",
            color: "",
            row: 1,
            index: "0",
            celldata: merged,
            calcChain: mergedformula,
            defaultRowHeight: defaultRowHeight,
            config: {
              columnlen: savedColumnWidths,
              colhidden: {
                0: false,
              },
              rowlen: {
                0: 50,
              },
              authority: {
                sheet: 1,
                hintText: "Kolom ini tidak bisa anda edit",
                allowRangeList: [
                  {
                    "name": "area",
                    "sqref": mergedDataRO,
                  },
                ],
              },
            },
          },
        ],
        enableAddRow: false,
        textWrapMode: true,
        hook: {
          frozenCreateAfter: function (frozen) {
            //console.log("frozen ",frozen)
          },
          cellRenderAfter: function (cell, position, sheetFile, ctx) {
            if (b < 1) {
              const data = [];
              const endIndex = 150;
              for (let i = 0; i <= endIndex; i++) {
                data.push(i);
              }
              var columnWidths = luckysheet.getColumnWidth(data);
              const colWidthA = columnWidths[1];
              for (let columnIndex of data) {
                const width = columnWidths[columnIndex];
                columnWidths[columnIndex.toString()] = width;
              }
              localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));

              setTimeout(function () {
                b = 0;
              }, 500);
            }
            b++;
          },
          commentUpdateBefore: async function (r, c, value) {
            const id = luckysheet.getCellValue(r, 0);
            const column = nama_column[c];
            const formData = new FormData();
            const cleanedValue = value.trim().replace(/\r\n?|\n/g, ' ');
            formData.append("code_1", id);
            formData.append("code_2", tipedata);
            formData.append("code_3", "");
            formData.append("row", r);
            formData.append("col", c);
            formData.append("comment", cleanedValue);
            formData.append("column", column);
            try {
              const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
              notify_update_br_cont("Note sukses di tambahkan");
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          },
          cellEditBefore: function (r, c, value, isRefresh) {
            // console.log("Edit before");
            //  console.log(value);
          },
          cellUpdateBefore: function (r, c, value, isRefresh) {
            testdata = value;
            if (!isValidFormula(value)) {
              //console.info('cellUpdateBefore', r, c, value, isRefresh);
              return true;
            } else {
              //console.warn('Validation failed for cellUpdateBefore', r, c, value, isRefresh);
              return false;
            }
          },
          cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
            if (r > 0) {
              if (oldValue && newValue) {
                const newData = newValue.m;
                const id = luckysheet.getCellValue(r, 0);
                var datax = {};
                var nilai = newData;
                if (nama_column[c] !== undefined) {
                  if (nama_column[c] == "harga_custom" || nama_column[c] == "harga_kapal" || nama_column[c] == "lainnya" || nama_column[c] == "Jual") {
                    nilai = parseFloat(nilai);
                  }
                  var column = nama_column[c];
                  //column = column.replace(/ /g, "_");
                  if (nilai !== null && nilai !== undefined && nilai !== "") {
                    // console.log(nama_column[c]);
                    // console.log(data_pilihan);
                    if (data_pilihan.includes(nama_column[c])) {
                      //console.log("oke ada ", nilai.replace(/,/g, ''));
                      datax[nama_column[c]] = nilai.replace(/,/g, '');
                    } else {
                      datax[nama_column[c]] = nilai;
                    }
                  } else {
                    datax[nama_column[c]] = "";
                  }
                  if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
                    datax["id"] = id;
                    axios.post(`${hostcont}ReactUpdateBrcontainers`, datax, config)
                      .then(response => {
                        if (response.data.status == "success") notify_update_br_cont("Data Cont Updated");
                        //console.log('POST request successful:', response.data);
                      })
                      .catch(error => {
                        console.error('Error fetching data:', error.response.data.message);
                        notify_cant_update_br_cont(error.response.data.message);
                        // luckysheet.setCellValue(r, c, "");
                        luckysheet.refresh();
                      });
                  }
                } else {
                  notify_cant_update_br_cont("Gagal!. Silahkan Refresh halaman ini.");
                }
              }
            }
          },
          cellEditBefore: function (range) {
            const c = range[0].column[0];
            var title_column_select = luckysheet.getCellValue(0, c);
          },
          rangeSelect: function (index, sheet) {
            //console.log("index ",index);
            // console.log("test 2 ", sheet);
            // setTimeout(() => {
            //luckysheet.setRangeShow({row:[1,1],column:[1,1]})
            // console.log("test 5 ", luckysheet.getRange());
            // let cekdata =  removeDuplicates(luckysheet, luckysheet.getRange());
            // console.log("hasil cek : ",cekdata)
            // }, 5000);
            //luckysheet.setRangeShow("{row:[0,1],column:[0,1]}",{show:false})

            var column = sheet[0].column[0];
            if (sheet[0].column[0] <= end_column_freze) {
              setleftScrollbar(true);
            } else {
              setleftScrollbar(false);
            }
            var top = sheet[0].top;
            var left = sheet[0].left;
            var height = sheet[0].height;
            kiri = left;
            atas = top;
            tinggi = height;
            filter_data(column, top, left, height);
          },

          cellMousedown: function (cell, postion, sheetFile, ctx) {
            // console.log("klik ", ctx)
            // console.log("cek ",luckysheet.getRangeAxis())
            var dat = postion.r + "" + postion.c;
            if (type_row == dat) {
              type_jml++;
            } else {
              type_jml = 0;
            }
            type_row = postion.r + "" + postion.c;

            if (type_jml > 0) {
              if (cell.ct.t == "l") {
                const id = luckysheet.getCellValue(postion.r, 0);
                const judul = luckysheet.getCellValue(0, postion.c);
                var dataKirimModal = {
                  id: id,
                  nama: judul,
                  role: localStorage.getItem('role'),
                  token:token
                };
                setDataModal(dataKirimModal);
                toggleModal();
              }
            }
          },
          afterzoomfiki: function (ration) {
            createRatioZoom(ration);
            luckysheet.setCellFormat(1, 1, "ct", { fa: "#,###", t: "n" })
            luckysheet.jfrefreshgrid();
          },
          aftercreatefilterfiki: function (data) {
            const freezen_3 = document.getElementById('freezen_3');
            if (freezen_3) {
              freezen_3.style.zIndex = '20';
            }
          },
          sheetCopyAfter: function (range, data) {
            // console.log("paste ",range)
            // console.log("data ",data)
          },
          updated: function (operate) {
            //console.log("hasil operate: ",operate);
            let data = luckysheet.getLuckysheetfile();
            if (operate) {
              if (operate.ctrlType == "resizeR") {
                let dataukuran = operate.curconfig.rowlen;

                const keys = Object.keys(dataukuran);
                const lastKey = keys[keys.length - 1];
                const lastValue = dataukuran[lastKey];

                saveheighrow(lastValue);
              }
            }
          },

          rangeSelect: function (data1, data2) {
            //  console.log("data ", data1);
            //  console.log("data2 ", data2);
          },

          rangePasteBeforefiki: function (range, data, fki) {
            // console.log("range ",fki.luckysheetfile[0].luckysheet_select_save[0])
            // console.log("data ",fki)
            const isConfirmed = window.confirm('Yakin akan melakukan paste data?');
            if (isConfirmed) {
              setTimeout(() => {
                let jsonData = fki.luckysheetfile[0].luckysheet_select_save[0];
                const rows = jsonData.row;
                const columns = jsonData.column;
                // extra columnya fk
                const [startRow, endRow] = rows;
                const [startCol, endCol] = columns;

                var dataArray = [];
                var idCounter = 1;

                for (let row = startRow; row <= endRow; row++) {
                  let datax = { id: luckysheet.getCellValue(row, 0) }; // Convert to string and remove leading/trailing spaces

                  for (let col = startCol; col <= endCol; col++) {
                    const nilai = luckysheet.getCellValue(row, col);
                    datax[nama_column[col]] = nilai !== null && nilai !== undefined && nilai !== "" ? nilai.toString().trim() : ""; // Convert to string and remove leading/trailing spaces
                  }
                  dataArray.push(datax);
                }
                //console.log(dataArray);
                UpdateFromPaste(dataArray);
              }, 400);
            } else {
              return false;
            }
          }
        },
      }

      options.loading = {
        image: () => {
          return `<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
					</svg>`
        },
        imageClass: "loadingAnimation"
      }

      if (totaldataasli > 0) {
        luckysheet.create(options)
        setIsLoading(false);
        setTimeout(() => {
          if (luckysheet) {
            sumDataKerja(luckysheet, nama_column);
          }
        }, 500);
      } else {
        notify_cant_update_br_cont("Data kosong", 2500);
        setIsLoading(false);
      }

      setTimeout(() => {
        bikinselect();
      }, 500);

      function UpdateFromPaste(datax) {
        axios.post(`${hostcont}ReactUpdateBrcontainerspaste`, datax, config)
          .then(response => {
            if (response.data.status == "success") notify_update_br_cont("Data Cont Updated");
            //console.log('POST request successful:', response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error.response.data.message);
            notify_cant_update_br_cont(error.response.data.message);
            // luckysheet.setCellValue(r, c, "");
            luckysheet.refresh();
          });
      }

      function isValidFormula(value) {
        const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
        return formulaPattern.test(value);
      }

      // $(document).on('click', '.luckysheet-cols-menuitem', function () {
      //   helperFilter(end_column_freze + 1);
      // });

      $(document).on('mousedown', '.sp-thumb-el', function () {
        // var warna = $(this).attr('data-color');
        var title = $(this).attr('title');
        var datas = [];
        var jlh = 0;
        var selectedRange = luckysheet.getRange();
        //console.log("selectedRange ", selectedRange)
        if (!selectedRange) {
          console.error('No cells selected.');
          return;
        }
        var baris_awal = selectedRange[0].row[0];
        var baris_akhir = selectedRange[0].row[1];
        var kolom_awal = selectedRange[0].column[0];
        var kolom_akhir = selectedRange[0].column[1];

        const column = nama_column[kolom_awal];
        for (var row = baris_awal; row <= baris_akhir; row++) {
          for (var col = kolom_awal; col <= kolom_akhir; col++) {
            const id = luckysheet.getCellValue(row, 0);
            var datax = {};
            datax['id'] = id;
            datax['warna'] = title;
            datax['ColumnName'] = column;
            datax['ColumnField'] = "DATACONT";
            datas[jlh] = datax;
            jlh++;
          }
        }

        var dataxx = {};
        dataxx['data'] = JSON.stringify(datas);
        axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
          .then((response) => {
            notify_update_br_cont("Color sukses di tambahkan");
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      });

      function getColumnPosition(columnNumber) {
        let dividend = columnNumber + 1;
        let columnName = '';

        while (dividend > 0) {
          const modulo = (dividend - 1) % 26;
          columnName = String.fromCharCode(65 + modulo) + columnName;
          dividend = Math.floor((dividend - modulo) / 26);
        }
        return columnName;
      }

      const luckysheetContainer = document.getElementById('luckysheet');
      luckysheetContainer.addEventListener('scroll', () => {
        const scrollLeft = luckysheetContainer.scrollLeft;
        const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
        frozenColumns.forEach((column) => {
          column.style.transform = `translateX(${scrollLeft}px)`;
        });
      });

      function setSheetZoomWithValidation(defaultratio) {
        if (defaultratio != null && defaultratio !== undefined) {
          if (luckysheet) {
            luckysheet.setSheetZoom(defaultratio);
          }
        } else {
          console.error("Invalid defaultratio:", defaultratio);
        }
      }

      setTimeout(async function () {
        if (totaldataasli > 0) {
          if (luckysheet) {
            luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });
            //console.log("defaultratio ", defaultratio);

            if(!isMobileDevice()){

              setSheetZoomWithValidation(defaultratio);
            }
            helperFilter(end_column_freze + 1);
            setleftScrollbarstart();
          }
          //luckysheet.setRangeFormat("fc", "#ff0000", {range:["B2","C4:D5"]})
          //luckysheet.setRangeFilter("open",{range:"B1:B20"})
        }
      }, 500);

      function isMobileDevice() {
        const mobileScreenWidth = 768;
        return window.innerWidth < mobileScreenWidth;
      }

      $(document).ready(function () {
        const handleClickCustomFilter = () => {
          toggleFilterModal();
          //console.log('Element with id luckysheet-btn-filterCustom clicked!');
        };

        $('#luckysheet-btn-filterCustom').on('click', handleClickCustomFilter);
      });

      function filter_data(atas, kiri, tinggi) {
        $("#luckysheet-rich-text-editor").on('keyup', function () {
          const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
          $("#luckysheet-dataVerification-dropdown-List").hide();
          $(".dropdown-List-item").each(function () {
            const itemText = $(this).text().toUpperCase();
            if (itemText.indexOf(searchText) > -1) {
              $(this).show();
            } else {
              $(this).hide();
            }
          });

          // fiki
          const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
          const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
          const leftValue = parseFloat($("#luckysheet-input-box").css("left"));
          $("#luckysheet-dataVerification-dropdown-List").css({
            display: 'block',
            // left: leftValue - 44 + 'px',
            // top: topValue + editorHeight + editorHeight - 5 + 'px',
            right: 'auto',
            left: kiri + 'px',
            top: atas + tinggi + 'px',
          });
        });
      }
    };

    fetchData();


    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is hidden');
        luckysheet.refresh();
      } else {
        console.log('Tab is visible');
      }
    };

    // document.addEventListener('visibilitychange', handleVisibilityChange);

  }, []);

  function removeDuplicates(luckysheet, arr) {
    let uniqueArray = [];
    let seenMap = new Map();

    for (let obj of arr) {
      let key = JSON.stringify({ row: obj.row, column: obj.column });

      if (!seenMap.has(key)) {
        seenMap.set(key, true);
        //uniqueArray.push(obj);
      } else {
        callLuckysheetSetRangeShow(luckysheet, obj);
      }
    }

    return uniqueArray;
  }

  function callLuckysheetSetRangeShow(luckysheet, duplicateData) {
    // Convert duplicateData to the required format
    let rangeData = `{row:[${duplicateData.row[0]},${duplicateData.row[1]}],column:[${duplicateData.column[0]},${duplicateData.column[1]}]}`;
    // Call luckysheet.setRangeShow with the rangeData and show:false
    // console.log("rangeData ",rangeData)

    luckysheet.setRangeShow(rangeData, { show: false })
    //luckysheet.setRangeShow(`{rangeData}`, { show: false });
  }

  const updateTitle = (newTitle) => {
    setTitle(newTitle);
  };

  const convertToAlphabet = (number) => {
    let result = '';
    while (number >= 0) {
      result = String.fromCharCode(65 + (number % 26)) + result;
      number = Math.floor(number / 26) - 1;
    }
    return result;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        document.getElementById("luckysheet-dataVerification-dropdown-btn").style.setProperty('display', 'none', 'important');
        size_scroll_left = luckysheetContainerX.scrollLeft;
        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
        if (arrowCanvas !== null) {
          document.getElementById("luckysheet-postil-overshow").style.display = "none";
        }
      }
    });

    if (kiri) {
      size_scroll_left = 1
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
    });
    luckysheetContainerX.scrollLeft = 1;
  }

  const luckyCss = {
    margin: '75px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '90%',
    left: '0px',
    top: '0px',
    overflowX: 'auto',
  };

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  return (
    <div>
      {isLoading && <Loading />}
      <HeaderMenu title={title} subtitle={"Halaman untuk data Kontainer"} />
      <ToastContainer />
      <div id="luckysheet" style={luckyCss} />
      <Modal modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} />
      <ModalFilter modalVisible={modalFilterVisible} toggleModal={toggleFilterModal} data={datamodal} />

    </div>
  );
};

export default DATACONT;