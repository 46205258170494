import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import Header from '../../template/HeaderMenu';
import styleHs from '../../css/Hs.module.css';
import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import styles from '../../css/Hs.module.css';

registerAllModules();
const hostuser = process.env.REACT_APP_API_HOST_MASTER_USER
const hostLaporan = process.env.REACT_APP_API_HOST_LAPORAN;

const StuffingMerisa = () => {
 const [data, setData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [selectedRows, setSelectedRows] = useState(new Set());
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 1;
    const startYear = 2025;
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    const months = [
        { value: '01', label: 'Januari' },
        { value: '02', label: 'Februari' },
        { value: '03', label: 'Maret' },
        { value: '04', label: 'April' },
        { value: '05', label: 'Mei' },
        { value: '06', label: 'Juni' },
        { value: '07', label: 'Juli' },
        { value: '08', label: 'Agustus' },
        { value: '09', label: 'September' },
        { value: '10', label: 'Oktober' },
        { value: '11', label: 'November' },
        { value: '12', label: 'Desember' },
    ];


  const [tableHeight, setTableHeight] = useState(350); 
  const hotRef = useRef(null);
  const [hotInstance, setHotInstance] = useState(null);
  const [token, setToken] = useState(null);
  const [newRowValue, setNewRowValue] = useState(''); 
  let nama_pt = "ROLE";
  let tipe = "1";

  useEffect(() => {
    const setUP = async () => {
      const hot = hotRef.current.hotInstance;
      setHotInstance(hot);
      const token = await reloadToken();
      setToken(token);
    }
    setUP();
      const screenHeight = window.innerHeight;
      const headerHeight = 70;
      const footerHeight = 70;
      const calculatedHeight = screenHeight - headerHeight - footerHeight;
      setTableHeight(calculatedHeight);
  }, []);


  const handleSubmit = async () => {
    setLoading(true);
    if (!selectedMonth || !selectedYear) {
        alert('Pilih bulan dan tahun terlebih dahulu.');
        return;
    }
    try {
        const token = await reloadToken();
        const config = apiConfig(token);
        const payload = { bln: parseInt(selectedMonth, 10), thn: parseInt(selectedYear, 10) };

        if (hotInstance && token) {
            const res = await axios.post(`${hostLaporan}stuffingallHS`, payload, config);
            setData(res.data);
            hotInstance.addHook('afterColumnResize', handleColumnResize);
            loadColumnWidthsFromLocalStorage();
            initializeForSearchColumn();
              if (res && Array.isArray(res.data)) {
                hotInstance.loadData(res.data);
              } else {
                console.error("Invalid data format or data is missing:", res);
              }
          }

        setError(null);  // Reset error
    } catch (err) {
        setError(err.message || 'Failed to fetch data');
        console.error('Error fetching data:', err);
        setData([]);
    } finally {
        setLoading(false);
    }
};


  const initializeForSearchColumn = () => {
    const debounceFn = (colIndex, event) => {
      const filtersPlugin = hotInstance.getPlugin('filters');
      filtersPlugin.removeConditions(colIndex);
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
      filtersPlugin.filter();
    };

    const addEventListeners = (input, colIndex) => {
      input.addEventListener('keydown', (event) => {
        debounceFn(colIndex, event);
      });
    };

    const addInput = (col, TH) => {
      if (typeof col !== 'number') {
        return col;
      }

      if (col >= 0 && TH.childElementCount < 2) {
        TH.appendChild(getInitializedElements(col));
      }
    };

    const getInitializedElements = (colIndex) => {
      const div = document.createElement('div');
      const input = document.createElement('input');
      addEventListeners(input, colIndex);
      div.appendChild(input);
      return div;
    };

    const doNotSelectColumn = (event, coords) => {
      if (coords.row === -1 && event.target.nodeName === 'INPUT') {
        event.stopImmediatePropagation();
        hotInstance.deselectCell();
      }
    };

    hotInstance.updateSettings({
      afterGetColHeader: addInput,
      beforeOnCellMouseDown: doNotSelectColumn,
    });
  }

  const handleSaveButtonClick = () => {
    const config = apiConfig(token);
    var requestData = { data: hotInstance.getData() }; // No need to stringify
    var alertConfirm = window.confirm("Data akan di proses?");
    if (alertConfirm) {
      axios.post(`${hostuser}saveBiaya/PIB`, requestData, config)
        .then(response => {
          if (response.data.status === 'success') {
            hotInstance.loadData([]);
            var data = response.data.data;
            var kode = response.data.group;
            window.location.href = `/pibdetail/pib/${kode}`;
            console.log('data:', data);
            console.log('Kode:', kode);
          } else {
            var message = response.data.message;
            console.log('Error:', message);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error.response.data.message);
        });
    }
  };

  const loadColumnWidthsFromLocalStorage = () => {
    const savedWidths = {};
    for (let i = 0; i < hotInstance.countCols(); i++) {
      const width = localStorage.getItem(`${nama_pt}-${tipe}-${i}`);
      savedWidths[i] = width ? parseInt(width) : 100;
    }
    hotInstance.updateSettings({
      colWidths: function (column) {
        return savedWidths[column];
      },
    });
  };

  const handleColumnResize = (size, column) => {
    localStorage.setItem(`${nama_pt}-${tipe}-${column}`, size);
  };

  const handleInputChange = (event) => {
    setNewRowValue(event.target.value);
  };

  const handleAddRowClick = () => {
    const valueTotalinput = newRowValue;
    const hotInstance = hotRef.current.hotInstance;
    hotInstance.suspendRender();
    hotInstance.alter('insert_row_below',  hotInstance.ending, 1);
    hotInstance.resumeRender();
  };

  const handleClick = (data) => {
    if (data == "role"){
      window.location.href = "/role";
    } else if (data == "role_detail"){
      window.location.href = "/roledetail";
    } else if (data == "masteruser"){
      window.location.href = "/masteruser";
    }
  };
  

  const handleAfterCreateRow = async (index) => {
    var i = parseInt(index);
    try {
      const response = await axios.post(`${hostuser}Role/New`, null, apiConfig(token));
      if (response.data.status === 'success') {
        hotInstance.setDataAtCell(i, 0, response.data.data);
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAfterChange = async (changes, source) => {
    if (source === 'edit') {
      setTimeout(async function () {
        const rowIndex = changes[0][0];
        const id = hotInstance.getDataAtCell(rowIndex, 0);
        const dataBank = {
          "nama_role": hotInstance.getDataAtCell(rowIndex, 1),
          "role": hotInstance.getDataAtCell(rowIndex, 2),
          "role_multi": hotInstance.getDataAtCell(rowIndex, 3),
        };
        try {
          const response = await axios.post(`${hostuser}Role/${id}`, dataBank, apiConfig(token));
          if (response.data.status === 'success') {
           
          } else {
            const message = response.data.message;
            console.log('Error:', message);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }, 100);
    }
  };

  const handleAfterRemoveRow = async (index, amount, physicalRows, [source]) => {
    const id = hotInstance.getDataAtCell(index, 0);
  
    // Ask for confirmation before proceeding with the delete
    const confirmed = window.confirm("Are you sure you want to delete this role?");
  
    if (!confirmed) {
      // hotInstance.undo();
      return;
    }
  
    try {
      const response = await axios.post(`${hostuser}RoleDel/${id}`, null, apiConfig(token));
  
      if (response.data.status === 'success') {
        // Handle success (if needed)
      } else {
        const message = response.data.message;
        console.log('Error:', message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    // Use dangerouslySetInnerHTML to render HTML content
    if (typeof value === 'string') {
        td.innerHTML = value;
    }
};

const columns = [
  { type: 'text', title: 'ID', align: 'center' ,readOnly: true  },
  { type: 'text', title: '<b>STUFFING</b>', wordWrap: false,align: 'center',readOnly: true },
  { type: 'text', title: '<b>LOAD DATE</b>', wordWrap: false,align: 'center',readOnly: true },
  { type: 'text', title: '<b>ETA</b>', wordWrap: false,align: 'center',readOnly: true },
  { type: 'text', title: '<b>FEET</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>SALES</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>PARTAI</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>AJU</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>LINE</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>CNEE</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>ITEM</b>', wordWrap: false, readOnly: true },
  { type: 'text', title: '<b>TYPE</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>PORT</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>MARKING</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>CONTAINER NO</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>JML CONT</b>',wordWrap: false,readOnly: true },
  { type: 'text', title: '<b>TANDAI</b>'},
];
  const colHeaders = columns.map(column => column.title);

  const selectStyle = {
    // Define your custom CSS styles here
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '4px',
    fontSize: '16px',
    width: 'auto',
    height: '10%',
};

const selectTitleComponent = {
    padding: '4px',
    margin: '5px',
    fontWeight: 'bold'
}

  return (
    <div>
      <Header title="DATA CONTAINER STUFFING" />
      <div>
      <div className="row valign-wrapper">
                <div className="input-field col s4">
                    <span style={selectTitleComponent}>CARI DATA :</span>
                    <select style={selectStyle}
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                        <option value="" disabled>Pilih Bulan</option>
                        {months.map((month) => (
                            <option key={month.value} value={month.value}>{month.label}</option>
                        ))}
                    </select>

                    <select style={selectStyle}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        <option value="" disabled>Pilih Tahun</option>
                        {years.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>

                    <button className="btn_kirim" onClick={handleSubmit}>TAMPILKAN DATA</button>
                    
                </div>
            </div>

            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}

            <div className={styles.hotTableWrapper}>
                <HotTable 
                    ref={hotRef}
                    settings={{
                    afterCreateRow: handleAfterCreateRow,
                    afterChange: handleAfterChange,
                    beforeRemoveRow: handleAfterRemoveRow,
                    stretchH: 'all', // Stretch columns to fill available width
                    autoColumnSize: true, // Enable automatic column sizing
                    }}
                    autoRowSize={{ syncLimit: '100%' }}
                    renderer={customRenderer}
                    contextMenu={true}
                    rowHeaders={true}
                    filters={true}
                    outsideClickDeselects={false}
                    allowInsertColumn={true}
                    height={tableHeight}
                    width="100%"
                    readOnly={false}
                    manualColumnResize={true}
                    dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
                    columns={columns}
                    licenseKey="non-commercial-and-evaluation"
                    hiddenColumns={{
                    copyPasteEnabled: true,
                    indicators: true,
                    columns: [0]
                    }}
                />
            </div>


        {/* <div className="positionfix" style={{ textAlign: 'left' }}>
            <div id="footer_data" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>
            <input className={`${styleHs.inputText}`} id="new_add_row" value={newRowValue} onChange={handleInputChange} type="text" placeholder="0"size="3" maxLength="2"/>
            <button className={`${styleHs.button} ${styleHs.buttonnew}`} id="btn_save" onClick={() => handleAddRowClick()}>
               +  Add New Row
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("role")}>
              Role
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("role_detail")}>
              Role Detail
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("masteruser")}>
              Master User
            </button>
            </div>
        </div> */}

      </div>
    </div>
  );
};

export default StuffingMerisa;