export const formatPrice = (value) => {
  const validInput = /^[0-9,.]*$/;

  if (!validInput.test(value)) {
    return;
  }

  // Remove any existing commas
  let cleanedValue = String(value).replace(/,/g, "");

  // Convert the string to a number
  let number = parseFloat(cleanedValue);

  // Check if it's a valid number
  if (isNaN(number)) {
    return "Invalid number";
  }

  // Split the number into the integer and decimal parts
  let parts = cleanedValue.split(".");

  // Format the integer part with commas
  parts[0] = parseInt(parts[0], 10).toLocaleString("en-US");

  // Rejoin the integer and decimal parts, if any
  return parts.join(".");
};


export const handleNumericInput = (e, setValue) => {
  const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Hanya angka
  setValue(numericValue);
};

export function formatDate(dateString) {
  const dateParts = dateString.split('-');  // Split the date string by hyphens
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;  // Rearrange to DD-MM-YYYY
}