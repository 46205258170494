import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HeaderMenu from '../../template/HeaderMenu';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import styles_react from '../../css/Reacttable.module.css';
import styles from '../../css/form.module.css';
import { handleNumericInput } from '../../helper/formatPrice';

 const hostizin = process.env.REACT_APP_API_HOST_IZIN;
 //const hostizin = 'https://api-v1.appblueray.com/izin/';

const SpiADD = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedRowIds, setExpandedRowIds] = useState({});
  //inputan
  const [ptOptions, setPtOptions] = useState([]);
  const [v_namaPt, setNamaPt] = useState('');
  const [v_noSpi, setNoSpi] = useState('');
  const [v_jenis, setJenis] = useState('');
  const [val_jumlah, setJumlah] = useState('');
  const [v_tanggal, setTanggal] = useState('');
  const [v_tanggalBerlaku, setTanggalBerlaku] = useState('');
  const [v_tanggalBerakhir, setTanggalBerakhir] = useState('');

  useEffect(() => {
    fetchDataPT();
  }, []);

  const fetchDataPT = async () => {
    setLoading(true);
    try {
      const token = await reloadToken(); 
      const config = apiConfig(token);  
      const response = await axios.post(`${hostizin}pt`, null, config);  
      const resultData = response.data?.data || []; 

      setPtOptions(Array.isArray(resultData) ? resultData : []);
      setError(null); 
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
      console.error('Error fetching data:', err);
      setPtOptions([]); 
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const isConfirmed = window.confirm('Are you sure you want to update the data?');
    if (!isConfirmed) {
      return;
    }

    if (!v_namaPt || !v_noSpi || !v_jenis || !v_tanggal || !v_tanggalBerlaku || !v_tanggalBerakhir) {
      alert('Silahkan lengkapi data terlebih dahulu!');
      return;
    }

    setLoading(true);
    const token = await reloadToken();
    const config = apiConfig(token);

    const formData = new FormData();
    formData.append('nama_pt', v_namaPt);
    formData.append('no_spi', v_noSpi);
    formData.append('jenis', v_jenis);
    formData.append('jumlah', val_jumlah);
    formData.append('tgl_document', v_tanggal);
    formData.append('tgl_berlaku_spi', v_tanggalBerlaku);
    formData.append('tgl_expired', v_tanggalBerakhir);
    // formData.append('status', "0");

    try {
      const response = await axios.post(`${hostizin}save`, formData, config);
      console.log('Data saved successfully:', response.data.data);

      window.location.href = "/spi-new/" +  response.data.data;
     
      
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };

  const selectTitleComponent = {
    padding: '4px',
    margin: '5px',
    fontWeight: 'bold'
  }

  return (
    <div>
      <HeaderMenu title="PERSETUJUAN IMPOR" subtitle="Halaman untuk data Kontainer" />
      <ToastContainer />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      <div className={styles.tableContainerchild}>
        <div className={styles.inputFieldWrapper}>
          <div className={`input-field ${styles.inputField}`}>
          <select id="nama-pt"  value={v_namaPt} onChange={(e) => setNamaPt(e.target.value)}>
          <option value="" disabled selected>- PILIH PT -</option>
            {ptOptions.map((pt, index) => (
              <option key={index} value={pt}>{pt}</option>
            ))}
          </select>
            <label htmlFor="nama-pt">Nama PT</label>
          </div>

          <div className={`input-field ${styles.inputField}`}>
          <input
            id="no-spi"
            type="text"
            size={20}
            value={v_noSpi}
            onChange={(e) => setNoSpi(e.target.value)}
          />
          <label htmlFor="no-spi">No SPI</label>
        </div>

        <div className={`input-field ${styles.inputField}`}>
          <input
            id="jenis"
            type="text"
            size={60}
            value={v_jenis}
            onChange={(e) => setJenis(e.target.value)}
          />
          <label htmlFor="jenis">Jenis</label>
        </div>

        {/* <div className={`input-field ${styles.inputField}`}>
          <input
            id="jumlah"
            type="text"
            size={10}
            value={val_jumlah}
            onChange={(e) => handleNumericInput(e, setJumlah)}
          />
          <label htmlFor="jumlah">Jumlah</label>
        </div> */}

        <div className={styles.inputFieldsWrapper}>
          <div className={`input-field ${styles.inputField}`}>
            <input
              id="tanggal"
              type="date"
              value={v_tanggal}
              onChange={(e) => setTanggal(e.target.value)}
              size={60}
            />
            <label htmlFor="tanggal">Tanggal</label>
          </div>

          <div className={`input-field ${styles.inputField}`}>
            <input
              id="tanggal-berlaku"
              type="date"
              value={v_tanggalBerlaku}
              onChange={(e) => setTanggalBerlaku(e.target.value)}
              size={60}
            />
            <label htmlFor="tanggal-berlaku">Tanggal Berlaku</label>
          </div>

          <div className={`input-field ${styles.inputField}`}>
            <input
              id="tanggal-berakhir"
              type="date"
              value={v_tanggalBerakhir}
              onChange={(e) => setTanggalBerakhir(e.target.value)}
              size={60}
            />
            <label htmlFor="tanggal-berakhir">Tanggal Berakhir</label>
          </div>
          </div>
          </div>

        <div>
          <button className={styles.btnSave} type="button" onClick={handleSave}>Save & Next</button>
        </div>
      </div>

    </div>
  );
};

export default SpiADD;