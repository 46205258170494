// components/LoginComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styleLogin from '../css/Login.module.css';
import '../css/Materialize.css';
import '../css/Style.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encrypt, decrypt } from '../helper/Decrypte';

const host = process.env.REACT_APP_API_URL_ONLINE;
const notiferror = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2500
  });
};
const notifsuccess = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2500
  });
};

const LoginComponent = () => {
  const [accessCode, setAccessCode] = useState('');
  const [confirmCode, setConfirmCode] = useState('');

  useEffect(async() => {
    const user = decrypt(localStorage.getItem('user'));
    const pass = decrypt(localStorage.getItem('pass'));
    const verificationURL = host + "login";
    const formData = new FormData();
    if (user && pass) {
      formData.append('user', user);
      formData.append('pass', pass);
      try {
        const response = await axios.post(verificationURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("test");
        const newToken = response.data.token;
        localStorage.setItem('token', newToken);
        localStorage.setItem('pengguna', response.data.pengguna);
        localStorage.setItem('branch', response.data.branch);
        localStorage.setItem("user", encrypt(user));
        localStorage.setItem("pass", encrypt(pass));
        localStorage.setItem("role", response.data.role);
        if (response.data.role=="ADMSALES" || response.data.role=="TIMKONSEP" ) {
          window.location.href = "/stufing"; 
        }else {
          window.location.href = "/container"; 
        }
        
      } catch (response) {
        if (response.status === 500) {
          // localStorage.removeItem('user');
          // localStorage.removeItem('pass');
          // localStorage.removeItem('token');
          // localStorage.removeItem('pengguna');
          reloadtoken();
        } else {
          reloadtoken();
          console.log('Unexpected response status:', response.status);
          
        }
      }
    } else {
      reloadtoken();
    }

  }, []);

  const reloadtoken = () => {
    const url_header = host + "getCode";
    axios.get(url_header)
      .then(response => {
        setAccessCode(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  // dadta s
  const handleVerification = () => {
    const verificationURL = host + "login";
    const data = {
      user: accessCode,
      pass: confirmCode,
    };

    axios.postForm(verificationURL, data)
      .then(response => {
        //console.log('Verification successful!', response.data.token);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", encrypt(accessCode));
        localStorage.setItem("pass", encrypt(confirmCode));
        localStorage.setItem("pengguna", response.data.pengguna);
        localStorage.setItem('branch', response.data.branch);
        localStorage.setItem("role", response.data.role);
        if (response.data.role=="ADMSALES" || response.data.role=="TIMKONSEP" ) {
          window.location.href = "/stufing"; 
        }else {
          window.location.href = "/container"; 
        }

      })
      .catch(error => {
        notiferror(error.message);
        console.log('An error occurred:', error.message);
      });
  };

  return (
    <div className="vertical-layout page-header-light vertical-menu-collapsible vertical-menu-nav-dark preload-transitions 1-column login-bg blank-page blank-page" data-open="click" data-menu="vertical-menu-nav-dark" data-col="1-column">
      <div className="row">
            <ToastContainer />
        <div className="col s12">
              <div id="login-page" className="row">
                <div className="col s6 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                  {/* <form className="login-form"> */}
                  <div className="row">
                    <div className="input-field col s12">
                      <h5 className="ml-4">Authorization</h5>
                    </div>
                  </div>
                  <div className="row margin" style={{ height: '40px' }}>
                    <div className="input-field col s12">
                      <i className={`${styleLogin.materialiconslogin} ${styleLogin.prefix} ${styleLogin.pt-2}`} id="get_code">
                        fingerprint
                      </i>
                      <input className={`${styleLogin.styleText}`} id="access_code" readOnly type="text" value={accessCode} onChange={(e) => setConfirmCode(e.target.value)} />
                    </div>
                  </div>
                  <div className="">
                    <div className="input-field col s12">
                      <i className={`${styleLogin.materialiconslogin} ${styleLogin.prefix} ${styleLogin.pt-2}`}>lock_outline</i>
                      <input className={`${styleLogin.styleText}`} id="confirm_code" type="text" value={confirmCode} onChange={(e) => setConfirmCode(e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <button
                        id={`${styleLogin.bottonlogin}`} className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12" onClick={handleVerification}>
                        Verification
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
        </div>
    </div>
  );
};

export default LoginComponent;
