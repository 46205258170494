import React, { useEffect, useState } from 'react';
import axios, { formToJSON } from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { hide, bikinInfoSum, sumDataKerja, getFrozen, createRatioZoom, getRatioZoom, saveheighrow, getheighrow } from '../helper/hide';
import { ToastContainer, toast } from 'react-toastify';
import { dataconfig, showtoolbarConfig, ct } from '../helper/luckysheetHelper';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './modaldetail';
import Modalpreview from './modalpreview';

const tipedata = "FCL";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostfcl = process.env.REACT_APP_API_HOST_FCL;
const hostdatcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const ADMIN = "admin"
const POL = "pol"
const CNEE = "cnee"
const SALES = "sales"
const KONSEPTOR = "koseptor"
const TIPE_TRANS = "tipe_trans"
const MARKING = "marking"
const PORT = "port"
const STATUS_BL = "status_bl"
const TUJUAN_BONGKAR = "tujuan_bongkar"
const GROUPS = "group_bongkar"
const UK = "uk"
const PPN_BR = "ppn_br"
const COMMODITY = "commodity"
const GROUP_COMMODITY = "group_commodity"
const SENDBONGKARAN = "send_bongkaran"
const PEL = "pel"
const ITEM = "item"
const NEGARA = "negara"



const notify_cant_update_br_cont = (message, time = 1500) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
  });
};

const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 800,
  });
};


const FCL = () => {
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "FCLCOLUMN"
  const pagerow = "FCLROW"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  var type_row = "";
  var type_jml = 0;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  let valueGroupCommodity = "GROUP BATAM GOLDEN,GROUP BATAM";
  // let valuegroupbongkar = "MB 5,MB 8";
  var end_column_freze = 11;
  var size_scroll_left = 0;
  var defaultRowHeight = 30;
  var defaultratio = 1;

  const [modalVisible, setModalVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);

  const [modalVisible_V, setModalVisible_V] = useState(false);
  const [datamodal_V, setDataModal_V] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleModal_V = () => {
    setModalVisible_V(!modalVisible_V);
  };

  function callFunctionlocalstorage() {
    const frozenPosition = getFrozen();
    end_column_freze = frozenPosition;

    const ratiozoom = getRatioZoom();
    if (ratiozoom == null || ratiozoom == "" || ratiozoom == undefined) {
      defaultratio = 1;
    } else {
      defaultratio = ratiozoom;
    }

    const h_c = getheighrow();
    if (h_c == null || h_c == "") {
      defaultRowHeight = 30;
    } else {
      defaultRowHeight = h_c;
    }
  }

  useEffect(() => {
    callFunctionlocalstorage();
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
        initializeLuckySheet(newToken);
        
      }
    };

    const initializeLuckySheet = async (token) => {
      new Promise((resolve) => setTimeout(resolve, 100));
      const config = apiConfig(token);

      const getColumnNames = async () => {
        try {
          const config = apiConfig(token);
          const response = await axios.post(`${hostfcl}ReactBrcontainerDetailColumn`, null, config);
          return response.data.column;
        } catch (error) {
          console.error('Error fetching column names:', error);
          return [];
        }
      };

      const getCellData = async (data, savedColumnWidths, urutanload) => {
        try {
          const queryParams = new URLSearchParams(window.location.search);
          const kode = queryParams.get('kode');
          let url;
          if (kode) {
            url = `${hostfcl}ReactBrcontainersDetail/${kode}`;
          } else {
            url = `${hostfcl}ReactBrcontainersDetail`;
          }

          const response = await axios.post(url, { data, savedColumnWidths, urutanload }, config);
          return response;
        } catch (error) {
          console.error('Error fetching cell data:', error);
          return [];
        }
      };

      const cekStatusFile = async () => {
        try {
          const response = await axios.post(`${hostfcl}Cekstatusfile`, null, config);
          return response.data;
        } catch (error) {
          console.error('Error fetching cell data:', error);
          return [];
        }
      };

      const getDropdownMarking = async (data) => {
        try {
          const response = await axios.get(data, config);
          return response.data;
        } catch (error) {
          console.error('Error fetching column names:', error);
          return [];
        }
      };

      // const markingPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/marking/FCL`);
      const markingPromise = getDropdownMarking(`${hostdrop}MasterDropdownMarking/value`);
      const dropPortPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/port/FCL`);
      const dropcneePromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/cnee/FCL`);
      const droppolPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/pol/FCL`);
      const dropadminPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/admin/FCL`);
      const dropkonseptorPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/konseptor/FCL`);
      const dropsalesPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/sales/FCL`);
      const droptipePromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/tipe_fcl`);
      const dropGroupPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/group_bongkar`);
      const dropTujuanPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/tujuan_bongkar`);
      const dropPelPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/pel`);
      const dropCommodityPromise = getDropdownMarking(`${hostdrop}MasterDropdownComunity/commodity`);
      const dropitemPromise = getDropdownMarking(`${hostdrop}MasterDropdownItem/item`);
      const dropNegaraPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/negara/FCL`);





      const [marking, dropPort, dropcnee, droppol, dropadmin, dropkonseptor, dropsales, droptipe, dropGroup, dropTujuan,dropCommodity,dropPel,dropitem,dropNegara] = await Promise.all([
        markingPromise,
        dropPortPromise,
        dropcneePromise,
        droppolPromise,
        dropadminPromise,
        dropkonseptorPromise,
        dropsalesPromise,
        droptipePromise,
        dropGroupPromise,
        dropTujuanPromise,
        dropCommodityPromise,
        dropPelPromise,
        dropitemPromise,
        dropNegaraPromise,
      ]);

      const nama_column = await getColumnNames();
      const data_pilihan = ["custom", "kapal", "fe", "gudang", "biaya_lain", "inv_ppn_br", "biaya_trucking","sni"];
      let datahasil = [];
      const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
      for (let i = 0; i < cariindex.length; i++) {
        const columnNumber = cariindex[i];
        const columnPosition = getColumnPosition(columnNumber);
        datahasil.push(columnPosition)
      }

      let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
      if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
        savedColumnWidths = null;
      } else {
        savedColumnWidths = savedColumnWidths;
      }


      async function cekFile() {
        let cekfile = await cekStatusFile();
        if (cekfile.length !== 0) {
          if (cekfile.count > 0) {
            setTimeout(async function () {
              var dataKirimModal = {
                id: "1",
                nama: "test"
              };
              setDataModal_V(dataKirimModal);
              toggleModal_V();
            }, 2000)
          }
        }
      }
      // cekFile();
      const intervalId = setInterval("", 3600000);//1 jam

      let cellDataJsonA = [];
      let totaldata = 0;

      function isObjectEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      }

      async function loaddata(offset) {
        cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset);
        if (cellDataJsonA.length === 0) {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        } else {
          if (isObjectEmpty(cellDataJsonA.data)) {
            return {
              "data": [{}],
              "dataformula": [{}]
            };
          } else {
            totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
            for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
              if (cellDataJsonA.data.data[i].v.ct == "0") {//title
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "@",
                  "t": "s",
                };
                cellDataJsonA.data.data[i].v.bl = 1;
                cellDataJsonA.data.data[i].v.ff = 1;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "#,##0",
                  "t": "n",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 2;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "yyyy-MM-dd",
                  "t": "d",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "@",
                  "t": "l",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else if (cellDataJsonA.data.data[i].v.ct == "5") {//link
                cellDataJsonA.data.data[i].v.ct = {
                  "fa": "General",
                  "t": "g",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 0;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              } else {
                cellDataJsonA.data.data[i].v.ct = {//text
                  "fa": "@",
                  "t": "n",
                };
                cellDataJsonA.data.data[i].v.bl = 2;
                cellDataJsonA.data.data[i].v.ff = 2;
                cellDataJsonA.data.data[i].v.ht = 1;
                cellDataJsonA.data.data[i].v.fs = 9;
                cellDataJsonA.data.data[i].v.vt = 0;
                cellDataJsonA.data.data[i].v.tb = 2;
              }
            }
          }
          const data_all = cellDataJsonA.data;
          return data_all;
        }
      }

      const [page0Data, page1Data, page2Data, page3Data, page4Data, page5Data] = await Promise.all([
        loaddata(0),
        loaddata(1),
        loaddata(2),
        loaddata(3),
        loaddata(4),
        loaddata(5),
      ]);

      const page0 = page0Data || [];
      const page1 = page1Data || [];
      const page2 = page2Data || [];
      const page3 = page3Data || [];
      const page4 = page4Data || [];
      const page5 = page5Data || [];

      const merged = [
        ...page0.data,
        ...page1.data,
        ...page2.data,
        ...page3.data,
        ...page4.data,
        ...page5.data,
      ];

      const mergedformula = [
        ...page0.dataformula,
        ...page1.dataformula,
        ...page2.dataformula,
        ...page3.dataformula,
        ...page4.dataformula,
        ...page5.dataformula,
      ];

      const mergedDataRO = page1.readonly;//readonly 1 for all 
      const dataformula = await loaddata(0);
      const canformual = page0.dataformula;
      var testdata;
      options = {
        container: "luckysheet",
        title: "DataCont",
        lang: 'en',
        showinfobar: false,
        allowCopy: true,
        allowEdit: true,
        column: 0,
        filter: {
          ref: 'A1:C1',
        },
        forceCalculation: true,
        showtoolbarConfig: dataconfig,
        cellRightClickConfig: showtoolbarConfig,
        data: [
          {
            name: "Sheet",
            color: "",
            row: 1,
            index: "0",
            celldata: merged,
            calcChain: mergedformula,
            defaultRowHeight: defaultRowHeight,
            config: {
              columnlen: savedColumnWidths,
              colhidden: {
                0: false,
              },
              rowlen: {
                0: 40,
              },
              authority: {
                sheet: 1,
                hintText: "Kolom ini tidak bisa anda edit",
                allowRangeList: [
                  {
                    "name": "area",
                    "sqref": mergedDataRO,
                  },
                ],
              },
            },
          },
        ],
        enableAddRow: true,
        textWrapMode: true,
        hook: {
          cellRenderAfter: function (cell, position, sheetFile, ctx) {
            if (b < 1) {
              const data = [];
              const endIndex = 150;
              for (let i = 0; i <= endIndex; i++) {
                data.push(i);
              }
              var columnWidths = luckysheet.getColumnWidth(data);
              const colWidthA = columnWidths[1];
              for (let columnIndex of data) {
                const width = columnWidths[columnIndex];
                columnWidths[columnIndex.toString()] = width;
              }
              localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));

              setTimeout(function () {
                b = 0;
              }, 500);
            }
            b++;
          },
          rangePasteBefore: function (range, data) { },
          commentUpdateBefore: async function (r, c, value) {
            const id = luckysheet.getCellValue(r, 0);
            const column = nama_column[c];
            const formData = new FormData();
            const cleanedValue = value.trim().replace(/\r\n?|\n/g, ' ');
            formData.append("code_1", id);
            formData.append("code_2", tipedata);
            formData.append("code_3", "");
            formData.append("row", r);
            formData.append("col", c);
            formData.append("comment", cleanedValue);
            formData.append("column", column);
            try {
              const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
              notify_update_br_cont("Note sukses di tambahkan");
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          },
          cellEditBefore: function (r, c, value, isRefresh) {
            // console.log("Edit before");
            //  console.log(value);
          },
          cellUpdateBefore: function (r, c, value, isRefresh) {
            testdata = value;
            if (!isValidFormula(value)) {
              //console.info('cellUpdateBefore', r, c, value, isRefresh);
              return true;
            } else {
              //console.warn('Validation failed for cellUpdateBefore', r, c, value, isRefresh);
              return false;
            }
          },
          cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
            //console.log("testdata ",testdata)
            if (oldValue && newValue) {
              const newData = testdata;
              const id = luckysheet.getCellValue(r, 0);
              var datax = {};
              var nilai = newData;
              if (nama_column[c] !== undefined) {
                if (nama_column[c] == "harga_custom" || nama_column[c] == "harga_kapal" || nama_column[c] == "lainnya" || nama_column[c] == "Jual") {
                  nilai = parseFloat(nilai);
                }

                if (nilai !== null && nilai !== undefined && nilai !== "") {
                  datax[nama_column[c]] = nilai;
                } else {
                  datax[nama_column[c]] = "";
                }

                if (nilai !== null && nilai !== undefined && nilai !== "") {
                  if (data_pilihan.includes(nama_column[c])) {
                    datax[nama_column[c]] = nilai.replace(/,/g, '');
                  } else {
                    datax[nama_column[c]] = nilai;
                  }
                } else {
                  datax[nama_column[c]] = "";
                }
                if (oldValue.m !== newData) {
                  if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
                    datax["id"] = id;
                    if (validasidropdown(nama_column[c], nilai)) {
                      axios.post(`${hostfcl}React_UpdateBrcontainersDetail`, datax, config)
                        .then(response => {
                          if (response.data.status == "success")
                            var etaDateIndex = findColumnIndex("eta_date");
                            if (etaDateIndex !== null) {
                              //luckysheet.setCellValue(r, etaDateIndex, response.data.data.eta_date);
                            } else {
                              console.log("Kolom 'eta_date' tidak ditemukan.");
                            }

                          notify_update_br_cont("Data sukses di proses");
                          luckysheet.refresh();
                        })
                        .catch(error => {
                          console.error('Error fetching data:', error.response);
                          notify_cant_update_br_cont(error.response.data.message.toString());
                            try {
                              luckysheet.undo();
                            } catch (error) {
                                console.error("Terjadi error saat memanggil undo:", error.message);
                            }
                          // luckysheet.refresh();
                        });
                    } else {
                      notify_cant_update_br_cont("Data tidak valid");
                    }
                  }
                }
              } else {
                notify_cant_update_br_cont("Gagal!. Silahkan Refresh halaman ini.");
              }
            }
          },
          cellEditBefore: function (range) {
            const c = range[0].column[0];
            var title_column_select = luckysheet.getCellValue(0, c);
          },
          rangeSelect: function (index, sheet) {
            var column = sheet[0].column[0];
            if (sheet[0].column[0] <= end_column_freze) {
              setleftScrollbar(true);
            } else {
              setleftScrollbar(false);
            }
            var top = sheet[0].top;
            var left = sheet[0].left;
            var height = sheet[0].height;
            kiri = left;
            atas = top;
            tinggi = height;
            filter_data(column, top, left, height);
            
          },

          cellMousedown: function (cell, postion, sheetFile, ctx) {
            var dat = postion.r + "" + postion.c;
            if (type_row == dat) {
              type_jml++;
            } else {
              type_jml = 0;
            }
            type_row = postion.r + "" + postion.c;

            if (type_jml > 0) {
              if (cell.ct.t == "l") {
                const id = luckysheet.getCellValue(postion.r, 0);
                const judul = luckysheet.getCellValue(0, postion.c);
                var dataKirimModal = {
                  id: id,
                  nama: judul
                };
                setDataModal(dataKirimModal);
                toggleModal();
              }
            }
          },

          afterzoomfiki: function (ration) {
            createRatioZoom(ration);
          },

          updated: function (operate) {
            //console.log("hasil operate: ",operate);
            let data = luckysheet.getLuckysheetfile();
            if (operate) {
              if (operate.ctrlType == "resizeR") {
                let dataukuran = operate.curconfig.rowlen;

                const keys = Object.keys(dataukuran);
                const lastKey = keys[keys.length - 1];
                const lastValue = dataukuran[lastKey];
                saveheighrow(lastValue);
              }
            }
          },

          rangePasteBeforefiki: function (range, data, fki) {
          
            const isConfirmed = window.confirm('Yakin akan melakukan paste data?');
            if (isConfirmed) {
              setTimeout(() => {
                let jsonData = fki.luckysheetfile[0].luckysheet_select_save[0];
                const rows = jsonData.row;
                const columns = jsonData.column;
                // extra columnya fk
                const [startRow, endRow] = rows;
                const [startCol, endCol] = columns;

                var dataArray = [];
                var idCounter = 1;

                for (let row = startRow; row <= endRow; row++) {
                  let datax = { id: luckysheet.getCellValue(row, 0) }; 

                  for (let col = startCol; col <= endCol; col++) {
                    const nilai = luckysheet.getCellValue(row, col);
                    datax[nama_column[col]] = nilai !== null && nilai !== undefined && nilai !== "" ? nilai.toString().trim() : ""; // Convert to string and remove leading/trailing spaces
                  }
                  dataArray.push(datax);
                }
                //console.log(dataArray);
                UpdateFromPaste(dataArray);
              }, 400);
            } else {
              return false;
            }
          },
        },
      }

      options.loading = {
        image: () => {
          return `<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
					</svg>`
        },
        imageClass: "loadingAnimation"
      }

      if (totaldata > 0) {
        luckysheet.create(options)
      } else {
        notify_cant_update_br_cont("Error, Tidak dapat tersambung dengan server!", 2500);
      }

      function validasidropdown1(kolom, data) {
        return true;
      }

      function validasidropdown(kolom, data) {
        const branch = localStorage.getItem('branch');
        let valueppnArray;
        if (kolom == PPN_BR) {
          return rollbackvalidasidropdown(valueppn, data);
        }
        else if (kolom == UK) {
          return rollbackvalidasidropdown(valueukuran, data);
        }
        else if (kolom == MARKING) {
          return rollbackvalidasidropdown(marking, data);
        }
        else if (kolom == PORT) {
          return rollbackvalidasidropdown(dropPort, data);
        }
        else if (kolom == STATUS_BL) {
          return rollbackvalidasidropdown(valuestatusbl, data);
        }
        else if (kolom == TUJUAN_BONGKAR) {
          return rollbackvalidasidropdown(dropTujuan, data);
        }
        else if (kolom == GROUPS) {
          return rollbackvalidasidropdown(dropGroup, data);
        }
        else if (kolom == CNEE) {
          return rollbackvalidasidropdown(dropcnee, data);
        }
        else if (kolom == POL) {
          return rollbackvalidasidropdown(droppol, data);
        }
        else if (kolom == ADMIN) {
          return rollbackvalidasidropdown(dropadmin, data);
        }
        else if (kolom == SALES) {
          return rollbackvalidasidropdown(dropsales, data);
        }
        else if (kolom == KONSEPTOR) {
          return rollbackvalidasidropdown(dropkonseptor, data);
        }
        else if (kolom == TIPE_TRANS) {
          return rollbackvalidasidropdown(droptipe, data);
        }
        else if (kolom == COMMODITY) {
          return rollbackvalidasidropdown(dropCommodity, data);
        }
        else if (kolom == GROUP_COMMODITY) {
          return rollbackvalidasidropdown(valueGroupCommodity, data);
        }
        else if (kolom == PEL) {
          return rollbackvalidasidropdown(dropPel, data);
        }
        else if (kolom == NEGARA) {
          return rollbackvalidasidropdown(dropNegara, data);
        }
        else if (branch=="MB8"){
          if (kolom == ITEM) {
            return rollbackvalidasidropdown(dropitem, data);
         } else {
          return true;
         }
        }
        else {
          return true;
        }
      }

      function rollbackvalidasidropdown(valueppnArray, data) {
        if (data === null || data === undefined || data.trim() === "") {
          console.log("Data is empty or null.");
          return false;
        }
        if (valueppnArray.includes(data)) {
          return true;
        } else {
          return false;
        }
      }

      const findColumnIndex = (columnName) => {
        return nama_column.findIndex((col) => col === columnName);
      };

      function getColumnIndexByTitle(sheetId, title) {
        const sheetData = luckysheet.getSheetData(sheetId);
        if (!sheetData) return -1; // Sheet data not found

        const firstRowData = sheetData[0].data;
        for (let colIndex = 0; colIndex < firstRowData.length; colIndex++) {
          if (firstRowData[colIndex].v === title) {
            return colIndex;
          }
        }
        return -1;
      }

      $("#luckysheet-bottom-add-row").on("click", async function () {
        var data = $("#luckysheet-bottom-add-row-input").val();
        const currentDate = getCurrentDate();
        var b = luckysheet.getRange()[0].row[0];
        for (var i = 1; i <= data; i++) {
          try {
            const response = await axios.post(`${hostfcl}BrcontainersDetail/New`, null, config);
            //luckysheet.setCellValue(b, 0, response.data.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          b++;
        }
        window.location.reload();
      });

      function isValidFormula(value) {
        const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
        return formulaPattern.test(value);
      }

      function UpdateFromPaste(datax) {
        axios.post(`${hostfcl}ReactUpdateBrcontainerDetailpaste`, datax, config)
          .then(response => {
            if (response.data.status == "success") notify_update_br_cont("Data Cont Updated");
            //console.log('POST request successful:', response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error.response.data.message);
            notify_cant_update_br_cont(error.response.data.message);
            // luckysheet.setCellValue(r, c, "");
            luckysheet.undo();
          });
      }

      $(document).on('click', '.luckysheet-cols-menuitem', function () {
        helperFilter(11);
      });

      $(document).on('mousedown', '.sp-thumb-el', function () {
        // var warna = $(this).attr('data-color');
        var title = $(this).attr('title');
        var datas = [];
        var jlh = 0;
        var selectedRange = luckysheet.getRange();
        if (!selectedRange) {
          console.error('No cells selected.');
          return;
        }
        var baris_awal = selectedRange[0].row[0];
        var baris_akhir = selectedRange[0].row[1];
        var kolom_awal = selectedRange[0].column[0];
        var kolom_akhir = selectedRange[0].column[1];

        const column = nama_column[kolom_awal];
        for (var row = baris_awal; row <= baris_akhir; row++) {
          for (var col = kolom_awal; col <= kolom_akhir; col++) {
            const id = luckysheet.getCellValue(row, 0);
            var datax = {};
            datax['id'] = id;
            datax['warna'] = title;
            datax['ColumnName'] = column;
            datax['ColumnField'] = "FCL";
            datas[jlh] = datax;
            jlh++;
          }
        }
        var dataxx = {};
        dataxx['data'] = JSON.stringify(datas);
        axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
          .then((response) => {
            notify_update_br_cont("Color sukses di tambahkan");
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      });

      function getColumnPosition(columnNumber) {
        let dividend = columnNumber + 1;
        let columnName = '';

        while (dividend > 0) {
          const modulo = (dividend - 1) % 26;
          columnName = String.fromCharCode(65 + modulo) + columnName;
          dividend = Math.floor((dividend - modulo) / 26);
        }
        return columnName;
      }

      const luckysheetContainer = document.getElementById('luckysheet');
      luckysheetContainer.addEventListener('scroll', () => {
        const scrollLeft = luckysheetContainer.scrollLeft;
        const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
        frozenColumns.forEach((column) => {
          column.style.transform = `translateX(${scrollLeft}px)`;
        });
      });

      function isMobileDevice() {
        const mobileScreenWidth = 768;
        return window.innerWidth < mobileScreenWidth;
      }

      function handleSheetZoom() {
        if (document.visibilityState === "visible") {
          if (typeof defaultratio !== "undefined" && defaultratio >= 0.1 && defaultratio <= 2.0) {
            if (typeof luckysheet.setSheetZoom === "function") {
              document.addEventListener("visibilitychange", () => {
                if (document.visibilityState === "visible") {
                  luckysheet.setSheetZoom(defaultratio);
                }
              });
              
            } else {
              console.error("Luckysheet method setSheetZoom is not available");
            }
          } else {
            console.error("Invalid or undefined defaultratio:", defaultratio);
          }
        } else {
          console.log("Tab is inactive, skipping setSheetZoom");
        }
      }

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          handleSheetZoom();
        }
      });

      setTimeout(function () {
        const branch = localStorage.getItem('branch');
        var calcChainLength = 0;
      
        if (totaldata > 0) {
          const sheets = luckysheet.getAllSheets();
          if (sheets && sheets.length > 0 && sheets[0].data) {
            var total_data = sheets[0].data.length;
            calcChainLength = total_data + 1;
      
            // Safely set frozen rows/columns
            if (typeof luckysheet.setBothFrozen === "function") {
              luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });
            }
      
            // Perform helper filter
            helperFilter(11);
      
            // Set zoom only if defaultratio is valid
            if (!isMobileDevice() && typeof luckysheet !== "undefined" && typeof luckysheet.setSheetZoom === "function") {
              handleSheetZoom();
            } else {
              console.warn("Luckysheet is not initialized or setSheetZoom is not available.");
            }
      
            setleftScrollbarstart();
          } else {
            console.error("Invalid Luckysheet data or sheets");
          }
        }

        createDataVeri(ADMIN, dropadmin.join(', '));
        createDataVeri(POL, droppol.join(', '));
        createDataVeri(CNEE, dropcnee.join(', '));
        createDataVeri(SALES, dropsales.join(', '));
        createDataVeri(KONSEPTOR, dropkonseptor.join(', '));
        createDataVeri(TIPE_TRANS, droptipe.join(', '));
        createDataVeri(MARKING, marking.join(', '));
        createDataVeri(PORT, dropPort.join(', '));
        createDataVeri(GROUPS, dropGroup.join(', '));
        createDataVeri(TUJUAN_BONGKAR, dropTujuan.join(', '));
        createDataVeri(STATUS_BL, valuestatusbl);
        createDataVeri(UK, valueukuran);
        createDataVeri(PPN_BR, valueppn);
        createDataVeri(COMMODITY, dropCommodity.join(', '));
        createDataVeri(GROUP_COMMODITY, valueGroupCommodity);
        createDataVeri(PEL, dropPel.join(', '));
        createDataVeri(NEGARA, dropNegara.join(', '));

        if (branch=="MB8"){
          createDataVeri(ITEM, dropitem.join(', '));
        }

        function createDataVeri(kolom, value) {
          if (typeof luckysheet !== 'undefined') {
            console.log("total_data " + luckysheet)
            const kolom_ = nama_column.indexOf(kolom);
            if (kolom_ > 0) {
              const columnPosition = getColumnPosition(kolom_);
              const output = columnPosition + "1:" + columnPosition + "" + calcChainLength;

              console.log("output " + output)
              luckysheet.setRangeShow([output]);
              if (value.length > 0) {
                return luckysheet.setDataVerification(
                  {
                    "type": "dropdown",
                    "type2": null,
                    "value1": value,
                    "value2": "",
                    "checked": false,
                    "remote": false,
                    "prohibitInput": false,
                    "hintShow": false,
                    "hintText": ""
                  }
                );
              }
            }
          }
        }
      }, 500);

      function filter_data(c, atas, kiri, tinggi) {
        const datakolom = nama_column[c]
        const isValueValid = cekdata(datakolom);

        $("#luckysheet-rich-text-editor").on('keyup', function () {
          if (isValueValid) {

            const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
            $("#luckysheet-dataVerification-dropdown-List").hide();

            $(".dropdown-List-item").each(function () {
              const itemText = $(this).text().toUpperCase();

              if (itemText.indexOf(searchText) > -1) {
                console.log("data all 1")
                $(this).show();
              } else {
                console.log("data all 2")
                $(this).hide();
              }
            });

            // fiki
            const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
            const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
            const leftValue = parseFloat($("#luckysheet-input-box").css("left"));

            $("#luckysheet-dataVerification-dropdown-List").css({
              display: 'block',
              // left: leftValue - 44 + 'px',
              // top: topValue + editorHeight + editorHeight - 5 + 'px',
              right: 'auto',
              left: kiri + 'px',
              top: atas + tinggi + 'px',
            });
          } else {
            $("#luckysheet-dataVerification-dropdown-List").css({
              display: 'none',
              // left: leftValue - 44 + 'px',
              // top: topValue + editorHeight + editorHeight - 5 + 'px',
              right: 'auto',
              left: kiri + 'px',
              top: atas + tinggi + 'px',
            });
          }
        });
      }

      const cekdata = (v) => {
        const branch = localStorage.getItem('branch');
        const baseData = [ADMIN, POL, CNEE, SALES, KONSEPTOR, TIPE_TRANS, MARKING, PORT, STATUS_BL, TUJUAN_BONGKAR, GROUPS, UK, PPN_BR, COMMODITY, GROUP_COMMODITY, PEL,NEGARA];
        if (branch === "MB8") {
          baseData.push(ITEM);
        }
      
        return baseData.includes(v);
      };
      return () => clearInterval(intervalId);
    };

    fetchData();
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (luckysheet) { 
          luckysheet.refresh();
        } else{
          window.location.reload();
        }
      } else {

      }
    };

    // document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const convertToAlphabet = (number) => {
    let result = '';
    while (number >= 0) {
      result = String.fromCharCode(65 + (number % 26)) + result;
      number = Math.floor(number / 26) - 1;
    }
    return result;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        document.getElementById("luckysheet-dataVerification-dropdown-btn").style.setProperty('display', 'none', 'important');
        size_scroll_left = luckysheetContainerX.scrollLeft;
        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
        if (arrowCanvas !== null) {
          document.getElementById("luckysheet-postil-overshow").style.display = "none";
        }
      }
    });
    if (kiri) {
      size_scroll_left = 1
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      // console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

  const luckyCss = {
    margin: '75px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '90%',
    left: '0px',
    top: '0px',
    overflowX: 'auto',
  };

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  return (
    <div>
      <HeaderMenu title="BR CONTAINER LOAD" subtitle={"Page data & file FCL Stuffing"} />
      <ToastContainer />
      <div id="luckysheet" style={luckyCss} />
      <Modal modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} />
      <Modalpreview modalVisible={modalVisible_V} toggleModal={toggleModal_V} data={datamodal_V} />
    </div>
  );
};

export default FCL;