import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HeaderMenu from '../../template/HeaderMenu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import styles from '../../css/Reacttable.module.css';
import styles_form from '../../css/form.module.css';

const notif_error = (message, time = 1500) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: time,
  });
};

const notif_success = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 800,
  });
};

const hostizin = process.env.REACT_APP_API_HOST_IZIN;
// const hostizin = 'https://api-v1.appblueray.com/izin/';

const fullURL = window.location.href;
const path = window.location.pathname;
const match = path.match(/\/ceisa\/([^/]+)/);
const store_type_data = "store_type_data-" + fullURL;

const DataSPI = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRowIds, setExpandedRowIds] = useState({});
  const [v_type_data, set_type_data] = useState(localStorage.getItem(store_type_data));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = await reloadToken();
      const config = apiConfig(token);
      const response = await axios.post(`${hostizin}list`, null, config);
      const resultData = response.data?.data || [];  
      setData(Array.isArray(resultData) ? resultData : []);
      setOriginalData(Array.isArray(resultData) ? resultData : []);
      setError(null); 
      set_type_data("")
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
      console.error('Error fetching data:', err);
      setData([]);
      setOriginalData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataHistory = async (code) => {
    setData([]);
    setOriginalData([]);
    setLoading(true);
    const formData = new FormData();
    formData.append('code', code);
    try {
      const token = await reloadToken();
      const config = apiConfig(token);
      const response = await axios.post(`${hostizin}list/history`, formData, config);
      const resultData = response.data?.data || [];  
      setData(Array.isArray(resultData) ? resultData : []);
      setOriginalData(Array.isArray(resultData) ? resultData : []);
      setError(null); 
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
      console.error('Error fetching data:', err);
      setData([]);
      setOriginalData([]);
    } finally {
      setLoading(false);
    }
  };

  const columns = React.useMemo(() => [
    {
      Header: '',
      id: 'expander',
      Cell: ({ row }) => (
        <button className='btn-expand-new-react-table' onClick={() => toggleRowExpansion(row.id)}>
          {expandedRowIds[row.id] ? '-' : '+'}
        </button>
      ),
    },
    { Header: 'NO. SPI', accessor: 'no_spi' },
    { Header: 'NAMA PT', accessor: 'nama_pt' },
    { Header: 'JENIS', accessor: 'jenis' },
    {
      Header: 'JUMLAH',
      accessor: 'jumlah',
      Cell: ({ value }) => (
        <div
          style={{ textAlign: 'left' }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )
    },
    { Header: 'SISA', accessor: 'sisa_jumlah' },
    { Header: 'TGL DOKUMEN', accessor: 'tgl_document' },
    { Header: 'TGL BERLAKU', accessor: 'tgl_berlaku_spi' },
    { Header: 'TGL BERAKHIR', accessor: 'tgl_expired' },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: ({ value }) => {
        // Kondisi untuk status
        if (!value || value=='') {
          return <span style={{ color: 'blue' }}>Draft</span>; // Jika status = 1
        } else if (value == 1) {
          return <span style={{ color: 'green' }}>Active</span>; // Jika status = 1
        } else if (value == 2) {
          return <span style={{ color: 'red' }}>Expired</span>; // Jika status = 1
        }
        // Menambahkan style merah untuk "Non-Active"
        return <span style={{ color: 'red' }}>Non-Active</span>; // Jika status = 0 atau nilai lainnya
      }
    },
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }) => {
        const status = row.original.status; 
        const id = row.original.id; 
        const no_spi = row.original.no_spi; 
        console.log("status ",id)
        return (
          <div>
            {!status || status === '' ? (
                <button
                className='btn-activekan'
                onClick={() => handleDetail(no_spi)}
              >
                DETAIL
              </button>
              ) : status != 1 ? (""
              // <button
              //   className='btn-activekan'
              //   onClick={() => handleEdit(row.original)}
              // >
              //   ACTIVE
              // </button>
            ) : (
              <button
                className='btn-non-activekan'
                onClick={() => handleDelete(id)}
              >
                NON ACTIVE
              </button>
            )}
          </div>
        );
      },
    },
    
  ], [expandedRowIds]);

  const handleEdit = (row) => {
    console.log('Editing:', row);
    // Implement edit logic here
  };

  const handleDetail = (spi) => {
    window.location.href = "/spi-new/"+spi;
  }

  const handleDelete = (data_id) => {
    if (window.confirm('Data yang dinonaktifkan tidak bisa diaktifkan kembali')) {

      setTimeout(async function () {
        const token = await reloadToken();
        const config = apiConfig(token);
        const id = data_id;
        const formData = new FormData();
        formData.append('code', "0"); //KODE 0 ADALAH NON-ACTIVE
        try {
          const response = await axios.put(`${hostizin}updatestatus/${id}`, formData, config);
          if (response.data.status === 'success') {
           
          } else {
            const message = response.data.message;
            console.log('Error:', message);
          }

          console.log('Data saved successfully:', response.data);
          notif_success("Simpan data sukses!. Data sudah bisa di gunakan");
          window.location.href = "/spi";
          setLoading(false);

        } catch (error) {
          console.error('Error:', error);
          console.error('Error saving data:', error);
          notif_error('Error saving data:', error)
          setLoading(false);
        }
      }, 100);

    }
  };

  const tambahbaru = () => {
    window.location.href = "/spi-new";
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { globalFilter: '' }, // Menyimpan filter awal
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,  // Ambil setGlobalFilter dari tableInstance
  } = tableInstance;

  const handleFilterChange = (event) => {
    const filterValue = event.target.value.toLowerCase();
    if (!Array.isArray(originalData)) return;

    const filteredData = originalData.filter(row => {
      const mainRowMatch = Object.values(row).some(value =>
        String(value).toLowerCase().includes(filterValue)
      );

      const detailRowMatch = row.detail && Array.isArray(row.detail) && row.detail.some(detail =>
        Object.values(detail).some(value =>
          String(value).toLowerCase().includes(filterValue)
        )
      );

      return mainRowMatch || detailRowMatch;
    });

    setData(filterValue ? filteredData : originalData);
  };

  const toggleRowExpansion = (id) => {
    setExpandedRowIds((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const selectTitleComponent = {
    padding: '4px',
    margin: '5px',
    fontWeight: 'bold'
  }

  return (
    <div>
      <HeaderMenu title="PERSETUJUAN IMPOR" subtitle="Halaman untuk data Kontainer" />
      <ToastContainer />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      <div className={styles.view_main_tools}>
        <div className={styles.left}>
          <button className="btn-add-new-react-table" onClick={tambahbaru}>+ Tambah Baru</button>
        </div>
        <div className={styles.right}>

        <div className={`${styles_form.inputField}`}>
        <select id="nama-pt"  value={v_type_data} onChange={(e) => {
          set_type_data(e.target.value);
          localStorage.setItem(store_type_data, e.target.value);
          fetchDataHistory(e.target.value);
        }}>
          <option value="" disabled selected>- PILIH STATUS -</option>
          <option value="all">ALL</option>
          <option value="-">DRAFT</option>
          <option value="1">ACTIVE</option>
          <option value="0">NON-ACTIVE</option>
          <option value="2">EXPIRED</option>
          </select>
        </div>

          <input
            type="text"
            placeholder="Search..."
            onChange={handleFilterChange}
            className={styles.searchinput}
          />
        </div>
      </div>

      <div className="table-container">
        <table {...getTableProps()} className="custom-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                <th>No</th> {/* Tambahkan header untuk nomor urut */}
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr {...row.getRowProps()}>
                    <td>{index + 1}</td> {/* Render nomor urut tabel utama */}
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                  {expandedRowIds[row.id] && (
                    <tr>
                      <td colSpan={columns.length + 1}>
                        <table className={styles.detailTable}>
                          <thead>
                            <tr>
                              <th style={{ width: '20px' }}>No</th> {/* Header untuk nomor urut detail */}
                              <th style={{ width: '100px' }}>NO POST TARIF / HS</th>
                              <th>URAIAN BARANG</th>
                              <th style={{ width: '80px' }}>JUMLAH</th>
                              <th style={{ width: '80px' }}>SISA JUMLAH</th>
                              <th style={{ width: '80px' }}>SATUAN</th>
                              <th>NEGARA ASAL</th>
                              <th>NEGARA MUAT</th>
                              <th>PELABUHAN MUAT DI KPBPB</th>
                              <th style={{ width: '100px' }}>PELABUHAN TUJUAN</th>
                              <th>KETERANGAN / SEPESIFIKASI BARANG</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.original.detail.map((detail, detailIndex) => (
                              <tr key={detail.id}>
                                <td>{detailIndex + 1}</td> {/* Render nomor urut tabel detail */}
                                <td>{detail.hs_code}</td>
                                <td>{detail.uraian_brg}</td>
                                <td>{detail.jumlah}</td>
                                <td>{detail.sisa_jumlah}</td>
                                <td>{detail.satuan}</td>
                                <td>{detail.asal_negara}</td>
                                <td>{detail.negara_muat}</td>
                                <td>{detail.pelabuhan_muat_kpbpb}</td>
                                <td>{detail.pelabuhan_tujuan}</td>
                                <td>{detail.keterangan}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataSPI;