import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'select2';
import '../../css/Login.css';
import '../../css/Materialize.css';
import '../../css/Style.css';
import '../../css/new.css';
import reloadToken from '../../helper/reloadtoken';
import helperFilter from '../../helper/helperfilter';
import { dataconfig, showtoolbarConfig, ct, dataconfigfclpage } from '../../helper/luckysheetHelper';
import { hide, bikinInfoSum, sumDataKerja, getFrozen, createRatioZoom, getRatioZoom, saveheighrow, getheighrow, bikinselect, getFilterPageAll } from '../../helper/hide';
import HeaderMenu from '../../template/HeaderMenu';
import { apiConfig } from '../../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dataformula from '../../sheetFormula';
import pako from 'pako';
import { Autofill } from 'handsontable/plugins';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import './LaporanStuffing.css';  // Custom CSS file for styling

const tipedata = "FCL";
const hostLaporan = process.env.REACT_APP_API_HOST_LAPORAN;


const fullURL = window.location.href;


const notify_cant_update_br_cont = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 500,
    });
};
const notify_update_br_cont = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 500,
    });
};

const LaporanStuffing = () => {
    const [data, setData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = await reloadToken();
            const config = apiConfig(token);
            const response = await axios.post(`${hostLaporan}stuffing`, null, config);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const columns = React.useMemo(
        () => [
            { Header: 'SALES', accessor: 'sales' },
            { Header: 'HARI INI', accessor: 'qty_current_day' },
            { Header: 'TOTAL BULAN INI', accessor: 'total_qty_month' },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter: setTableGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter, useSortBy);

    // Handle global filter input
    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setGlobalFilter(value);
        setTableGlobalFilter(value);
    };

    return (
        <div>
            <HeaderMenu title="Laporan Stuffing / Loading Container" subtitle="Halaman untuk data Kontainer" />
            <ToastContainer />
            {/* <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search..."
                    value={globalFilter}
                    onChange={handleFilterChange}
                    className="search-input"
                />
            </div> */}
            <div className="table-container">
                <table {...getTableProps()} className="custom-table">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                        {/* Tambahkan baris subtotal */}
                        <tr>
                            <td style={{ fontWeight: 'bold' }}><strong>TOTAL</strong></td>
                            <td style={{ fontWeight: 'bold' }}>
                                {/* Jumlahkan kolom 'HARI INI' */}
                                {rows.reduce((total, row) => total + row.cells[1].value, 0)}
                            </td>
                            <td style={{ fontWeight: 'bold' }}>
                                {/* Jumlahkan kolom 'TOTAL BULAN INI' */}
                                {rows.reduce((total, row) => total + row.cells[2].value, 0)}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
    );
};

export default LaporanStuffing;