import React, { useEffect, useState } from 'react';
import axios, { formToJSON } from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import "../css/mn.css";
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { hide, bikinselect, getFilterPageAll } from '../helper/hide';
import { ToastContainer, toast } from 'react-toastify';
import { dataconfighistory, showtoolbarConfig, ct, dataconfiFclHistory } from '../helper/luckysheetHelper';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './modaldetail';
import Modalpreview from './modalpreview';
import ModalFilterFcl from './modalFilterFcl';

const FclMenu = () => {

  const [modalVisible, setModalVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);

  const [modalVisible_V, setModalVisible_V] = useState(false);
  const [datamodal_V, setDataModal_V] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleModal_V = () => {
    setModalVisible_V(!modalVisible_V);
  };

  const luckyCss = {
    margin: '75px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '90%',
    left: '0px',
    top: '0px',
    overflowX: 'auto',
  };

  return (
    <div>
      <HeaderMenu title="BERANDA" subtitle={"Page data & file FCL Menu"} />
      <ToastContainer />

      {/* <div id="luckysheet" style={luckyCss} /> */}
      {/* <Modal modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} /> */}
      {/* <Modalpreview modalVisible={modalVisible_V} toggleModal={toggleModal_V} data={datamodal_V} /> */}
      <div className='form_menu'>
        <div className='mn_form_row_title'>
          <div className='title_menu top'>CONTAINER LOADING</div>
        </div>

        <div className="mn_form_row" >
          <a href='/fcl-pic'>
            <div className="mn-card-template">
              <div className='mn-card-body'>
                <div className='mn-row'>
                  <div className='mn-column-left'>
                    <h6>STUFFING</h6>
                    <p className='menu_detail'>Berisi Data Container Yang belum Bongkar</p>
                  </div>
                  <div className='mn-column-right'  >
                    <img src='/images/stuffing.png' height="70" alt="Icon" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a href='/fclhistory'>
            <div className="mn-card-template">
              <div className='mn-card-body'>
                <div className='mn-row'>
                  <div className='mn-column-left'>
                    <h6>STUFFING HISTORY</h6>
                    <p className='menu_detail'>Berisi Data FCL yang sudah bongkar</p>
                  </div>
                  <div className='mn-column-right'>
                    <img src='/images/containers.png' height="65" alt="Icon" />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className='mn_form_row_title'>
          <div className='title_menu top'>PERIZINAN</div>
        </div>

        <div className="mn_form_row" >
          <a href='/fcl-pic'>
            <div className="mn-card-template">
              <div className='mn-card-body'>
                <div className='mn-row'>
                  <div className='mn-column-left'>
                    <h6>SNI</h6>
                    <p className='menu_detail'>Proses pengajuan barang SNI</p>
                  </div>
                  <div className='mn-column-right'  >
                    <img src='/images/file.png' height="60" alt="Icon" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a href='/fclhistory'>
            <div className="mn-card-template">
              <div className='mn-card-body'>
                <div className='mn-row'>
                  <div className='mn-column-left'>
                    <h6>LS</h6>
                    <p className='menu_detail'>Pengajuan izin barang lartas</p>
                  </div>
                  <div className='mn-column-right'>
                    <img src='/images/ls.png' height="65" alt="Icon" />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        
      </div>

    </div>
  );
}

export default FclMenu;

//  b,d,e,i,m,r,ac,ad, ae,af,ag,ah,aj,ak,al,aq, ar,as,at 

//  marking, cont, bl,aju,item, marking,