// modal.js
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import '../css/Modal.css';
import axios from 'axios';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import styleHs from '../css/Hs.module.css';
import reloadToken from '../helper/reloadtoken';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify_cant_update_br_cont = (message, time = 500) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: time,
    });
  };
  
  const notify_update_br_cont = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 800,
    });
  };

const Modal = ({ modalVisible, toggleModal, data }) => {
    const [role, setRole] = useState("");
    const [idpublic, setIDPublic] = useState("");
    const [tableHeight, setTableHeight] = useState(480);
    const hotRef = useRef(null);
    const isMounted = useRef(true);
    const [hotInstance, setHotInstance] = useState(null);
    const [token, setToken] = useState(null);
    const [newRowValue, setNewRowValue] = useState('');
    const [colHeaders, setColHeaders] = useState([]);
    let nama_pt = "ROLE";
    let tipe = "1";
    var loaddata = 0;
    const hostcont = process.env.REACT_APP_API_HOST_FCL;
    const hostfile = process.env.REACT_APP_API_HOST_UPLOAD;
    var data_all = [];
    var id_data = data.id;

    const setUP = async () => {
        const hot = hotRef.current && hotRef.current.hotInstance;
        if (isMounted.current && hot) {
            setHotInstance(hot);
            const newToken = await reloadToken();
            if (isMounted.current) {
                setToken(newToken);
            }
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
      setModalIsOpen(true);
    };
  
    // Function to close the modal

    useEffect(() => {
        const role = localStorage.getItem('role');
        setRole(role);

            // Call openModal when modalVisible prop is true
    if (modalVisible) {
        openModal();
      }
      
    }, [role]);

    const fetchData = async () => {
        try {
            const newToken = await reloadToken();
            setToken(newToken);
            if (hotRef.current && token && isMounted.current) {
                const hot = hotRef.current.hotInstance;
                setHotInstance(hot);
                const res = await loadData(token, data.id);
                if (res && Array.isArray(res.data.data)) {
                    if (hotRef.current.hotInstance) {
                        hotRef.current.hotInstance.loadData(res.data.data);
                        initializeForSearchColumn();
                        console.log("data : ",res.data.data)
                    } else {
                        console.error("Hot instance is null or undefined");
                    }
                } else {
                    console.error("Invalid data format or data is missing:", res);
                }
            }
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const isDataEmpty = !data || !data.nama;
    let interval;

    if (modalVisible) {
        document.body.classList.add('active-modal');
        fetchData();

        interval = setInterval(() => {
            const brdUpload = localStorage.getItem('brd_upload');
            if (brdUpload === '1') {
              fetchData(); // Panggil fetchData jika brd_upload memiliki nilai '1'
              localStorage.removeItem('brd_upload'); // Hapus item dari localStorage
              console.log("Data reloaded and 'brd_upload' cleared from local storage.");
            } else {
              console.log("No 'brd_upload' found or already processed.");
            }
        }, 1000);

    } else {
        document.body.classList.remove('active-modal');
        if (interval) {
            clearInterval(interval);  // Menghentikan interval
            interval = null;          // Membersihkan variabel interval
        }
    }

    const closeModal = () => {
        setModalIsOpen(false);
        toggleModal(); // Call the toggleModal function to close the modal
        if (role !== 'TIMKONSEP') {
            // window.location.reload();
        }
    };

    const uploadModal = () => {
        alert('Upload');
    };

    const downloadModal = () => {
        alert('Download');
    };

    const downloadPacketFile = () => {
       downloadPacketFile_(data.id);
    };

    const loadData = async (token, id) => {
        try {
            const config = apiConfig(token);
            const response = await axios.post(`${hostcont}ReactBrcontainersDetailFile`, { id }, config);
            return response;
        } catch (error) {
            console.error('Error fetching column names:', error);
            return [];
        }
    };

    const downloadPacketFile_ = async (id) => {
        try {
            const response = await axios.get(`${hostcont}Downloapacketfile/${id}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`, // Include your token here
                },
            });

            // Get the filename from the Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            console.log(contentDisposition)
            const filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
    
            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });
    
            // Create a link element to trigger the download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // Use the filename from the server
            document.body.appendChild(a);
    
            // Trigger a click on the link to start the download
            a.click();
    
            // Remove the link from the document
            document.body.removeChild(a);
    
            // Revoke the object URL to free up resources
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    
    
    const handleAfterCreateRow = async (index) => {
        // var i = parseInt(index);
        // try {
        //   const response = await axios.post(`${hostuser}Role/New`, null, apiConfig(token));
        //   if (response.data.status === 'success') {
        //     hotInstance.setDataAtCell(i, 0, response.data.data);
        //   } else {
        //     console.log('Error:', response.data.message);
        //   }
        // } catch (error) {
        //   console.error('Error:', error);
        // }
    };

    const handleAfterChange = async (changes, source) => {
         if (source === 'edit') {
        let old_data = changes[0][2];
        let new_data = changes[0][3];
        if (old_data!=new_data) {
          setTimeout(async function () {
            const rowIndex = changes[0][0];
            const id = hotInstance.getDataAtCell(rowIndex, 0);
            const datapost = {
              "msg": hotInstance.getDataAtCell(rowIndex, 3),
              "col": hotInstance.getDataAtCell(rowIndex, 4),
              "tipe": hotInstance.getDataAtCell(rowIndex, 5),
            };
            try {
              const response = await axios.post(`${hostfile}Updatecomment/${id}`, datapost, apiConfig(token));
              if (response.data.status === 'success') {
                notify_update_br_cont(response.data.message)
              } else {
                const message = response.data.message;
                console.log('Error:', message);
              }
            } catch (error) {
              console.error('Error:', error);
            }
          }, 100);
         }
        }
    };

    const handleAfterRemoveRow = async (index, amount, physicalRows, [source]) => {
        // const id = hotInstance.getDataAtCell(index, 0);
        // try {
        //   const response = await axios.post(`${hostuser}Role/${id}`, null, apiConfig(token));
        //   if (response.data.status === 'success') {

        //   } else {
        //     const message = response.data.message;
        //     console.log('Error:', message);
        //   }
        // } catch (error) {
        //   console.error('Error:', error);
        // }
    };

    const handleInputChange = (event) => {
        //setNewRowValue(event.target.value);
    };

    const handleAddRowClick = () => {
        const valueTotalinput = newRowValue;
        const hotInstance = hotRef.current.hotInstance;
        hotInstance.suspendRender();
        hotInstance.alter('insert_row_below', hotInstance.ending, 1);
        hotInstance.resumeRender();
    };

    const handleClick = (data) => {
        if (data == "role") {
            window.location.href = "/role";
        } else if (data == "role_detail") {
            window.location.href = "/roledetail";
        } else if (data == "masteruser") {
            window.location.href = "/masteruser";
        }
    };

    const initializeForSearchColumn = () => {
        const debounceFn = (colIndex, event) => {
            const filtersPlugin = hotInstance.getPlugin('filters');
            filtersPlugin.removeConditions(colIndex);
            filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
            filtersPlugin.filter();
        };

        const addEventListeners = (input, colIndex) => {
            input.addEventListener('keydown', (event) => {
                debounceFn(colIndex, event);
            });
        };

        const addInput = (col, TH) => {
            if (typeof col !== 'number') {
                return col;
            }

            if (col >= 0 && TH.childElementCount < 2 && col == 1) {
                TH.appendChild(getInitializedElements(col));
            }
        };

        const getInitializedElements = (colIndex) => {
            const div = document.createElement('div');
            const input = document.createElement('input');
            addEventListeners(input, colIndex);
            div.appendChild(input);
            return div;
        };

        const doNotSelectColumn = (event, coords) => {
            if (coords.row === -1 && event.target.nodeName === 'INPUT') {
                event.stopImmediatePropagation();
                hotInstance.deselectCell();
            }
        };
        try {
            hotInstance.updateSettings({
                afterGetColHeader: addInput,
                beforeOnCellMouseDown: doNotSelectColumn,
            });
        } catch (error) {
            //console.error('Error updating settings:', error);
        }
    }

    const columns = [
        { type: 'text', title: 'ID', align: 'center' },
        { type: 'text', title: '<b>Nama File</b>', editor: false, align: 'center' },
        { type: 'text', title: '<b>Upload</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Catatan</b>', className: 'htCenter'},
        { type: 'text', title: '<b>name column</b>', className: 'htCenter'},
        { type: 'text', title: '<b>tipe column</b>', className: 'htCenter'},
        { type: 'text', title: '<b>View</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Download</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Hapus</b>', editor: false, className: 'htCenter', renderer: 'html' },
    ];

    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        if (typeof value === 'string') {
            td.innerHTML = value;
        }
    };

    return createPortal(
        modalVisible ? (
            <div className="modal" >
                <div onClick={closeModal} className="overlay"></div>
                <div className="modal-content-detail">
                    {isDataEmpty ? (<p>Error, data is empty</p>) : (<div><h6>Data File</h6><p style={{ marginTop: '-5px' }}>Upload sesuaikan dengan nama list file</p><br></br></div>)}
                    <div>
                        <HotTable
                            ref={hotRef}
                            columns={columns}
                            settings={{
                                afterCreateRow: handleAfterCreateRow,
                                afterChange: handleAfterChange,
                                beforeRemoveRow: handleAfterRemoveRow
                            }}
                            data={data_all}
                            renderer={customRenderer}
                            contextMenu={true}
                            rowHeaders={true}
                            filters={true}
                            dropdownMenu={false}
                            outsideClickDeselects={false}
                            allowInsertColumn={true}
                            rowHeights={28}
                            autoRowSize={false}
                            manualColumnResize={true}
                            fixedColumnsStart={5}
                            height={420}
                            width={805}
                            colHeaders={colHeaders}
                            colWidths={[100, 180, 210, 75, 70, 95, 95, 95]}
                            licenseKey="non-commercial-and-evaluation"
                            className="htCenter"
                            hiddenColumns={{
                                copyPasteEnabled: true,
                                indicators: false,
                                columns: [0,4,5]
                            }}
                        >
                            {colHeaders.map((colName, index) => (
                                <HotColumn key={index} renderer={"html"} editor={false} />
                            ))}
                        </HotTable>
                    </div>
                    <p>
                        {/* Your modal content */}
                    </p>
                    {/* <button className="btn_close-modal btn_upload" onClick={uploadModal}>
                        Upload
                    </button>
                    <button className="btn_close-modal btn_download" onClick={downloadModal}>
                        Download
                    </button> */}
                    <br></br>
                    <button className="btn_close-modal btn_close" onClick={closeModal}>
                        Close
                    </button>

                    {role == "TIMKONSEP" ? (
                        <button className="btn_close-modal btn_download" onClick={downloadPacketFile}>
                            Download all
                        </button>
                    ) : ""}

                </div>
            </div>
        ) : null,
        document.body
    );
};

export default Modal;