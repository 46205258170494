import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import HeaderMenu from '../../template/HeaderMenu';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { HotTable, HotColumn } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';

import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import styles_react from '../../css/Reacttable.module.css';
import styles from '../../css/form.module.css';
import { formatDate } from '../../helper/formatPrice';
import { useParams } from 'react-router-dom';

const hostizin = process.env.REACT_APP_API_HOST_IZIN;
//const hostizin = 'https://api-v1.appblueray.com/izin/';

let nama_pt = "SPIDETAIL";
let tipe = "1";

const notif_error = (message, time = 1500) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: time,
  });
};

const notif_success = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 800,
  });
};


const SpiADD = () => {
  const { no_spi } = useParams();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedRowIds, setExpandedRowIds] = useState({});
  //hs
  const [tableHeight, setTableHeight] = useState(350);
  const hotRef = useRef(null);
  const [hotInstance, setHotInstance] = useState(null);
  const [token, setToken] = useState(null);
  const [newRowValue, setNewRowValue] = useState('');
  //inputan
  const [ptOptions, setPtOptions] = useState([]);
  const [v_ID, setID] = useState('');
  const [v_namaPt, setNamaPt] = useState('');
  const [v_noSpi, setNoSpi] = useState('');
  const [v_jenis, setJenis] = useState('');
  const [val_jumlah, setJumlah] = useState('');
  const [v_tanggal, setTanggal] = useState('');
  const [v_tanggalBerlaku, setTanggalBerlaku] = useState('');
  const [v_tanggalBerakhir, setTanggalBerakhir] = useState('');
  const [v_status_proses, setStatusProses] = useState('');
  const [v_nama_status_proses, setNamaStatusProses] = useState('');
  //all
  const [totalSum, setTotalSum] = useState(0);
  const [groupedSum, setGroupedSum] = useState({});


  useEffect(() => {
    const setUP = () => {
      const hot = hotRef.current.hotInstance;
      setHotInstance(hot);  // Menyimpan instance hot
    };

    setUP();  // Menjalankan setup
    const screenHeight = window.innerHeight;
    const headerHeight = 70;
    const footerHeight = 70;
    const calculatedHeight = screenHeight - headerHeight - footerHeight;
    setTableHeight(calculatedHeight);
  }, []);  // Ini akan dijalankan sekali ketika komponen pertama kali di-render

  // Monitor perubahan hotInstance
  useEffect(() => {
    if (hotInstance) {
      console.log("hotInstance updated:", hotInstance);
      ListDetail();  // Panggil ListDetail setelah hotInstance terupdate
    }
  }, [hotInstance]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = await reloadToken();
      const config = apiConfig(token);
      const response = await axios.post(`${hostizin}list/${no_spi}`, null, config);

      // Pastikan data diambil dari properti 'data' dalam respons JSON
      const resultData = response.data?.data || [];  // Gunakan nullish coalescing untuk mencegah undefined

      if (resultData.length > 0) {
        const firstData = resultData[0];  // Example: Get the first object from the array
        setID(firstData.id || '');
        setNamaPt(firstData.nama_pt || '');
        setNoSpi(firstData.no_spi || '');
        setJenis(firstData.jenis || '');
        setJumlah(firstData.jumlah || '');
        setTanggal(firstData.tgl_document || '');
        setTanggalBerlaku(firstData.tgl_berlaku_spi || '');
        setTanggalBerakhir(firstData.tgl_expired || '');
        setStatusProses(firstData.status || '');

        if (!firstData.status || !firstData.tgl_expired) {
          setNamaStatusProses("DRAFT");
        } else if (firstData.status == "1") {
          setNamaStatusProses("ACTIVE");
        } else if (firstData.status == "0") {
          setNamaStatusProses("NON-ACTIVE");
        } else if (firstData.status == "2") {
          setNamaStatusProses("EXPIRED");
        }
      }

      setError(null);  // Reset error
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
      console.error('Error fetching data:', err);
      setData([]);
      setOriginalData([]);
    } finally {
      setLoading(false);
    }
  };

  //HS
  const ListDetail = async () => {
    try {
      const token = await reloadToken();
      const config = apiConfig(token);

      if (hotInstance && token) {
        const res = await axios.post(`${hostizin}listdetail/${no_spi}`, null, config);
        setData(res.data);
        hotInstance.addHook('afterColumnResize', handleColumnResize);
        loadColumnWidthsFromLocalStorage();
        initializeForSearchColumn();
        if (res && Array.isArray(res.data)) {
          hotInstance.loadData(res.data);
          calculateSum();
        } else {
          console.error("Invalid data format or data is missing:", res);
        }
      }

      setError(null);  // Reset error
    } catch (err) {
      setError(err.message || 'Failed to fetch data');
      console.error('Error fetching data:', err);
      setData([]);
    }
  };

  const handleAfterCreateRow = async (index) => {
    const token = await reloadToken();
    const config = apiConfig(token);

    var i = parseInt(index);
    const formData = new FormData();
    formData.append('no_spi', no_spi);
    // formData.append('nama_pt', "");
    // formData.append('no_spi', "");
    // formData.append('jenis', "");
    // formData.append('jumlah', "");
    // formData.append('tgl_document', "");
    // formData.append('tgl_berlaku_spi', "");
    // formData.append('tgl_expired', "");
    try {
      const response = await axios.post(`${hostizin}detail/save`, formData, config);
      console.log("data ", response.data.data.id)
      if (response.data && response.data.data.id) {

        // const id = response.data.data.id;
        // hotInstance.setDataAtCell(i, 0, id);
        notif_success("Berhasil!");
        calculateSum();
        window.location.reload();
      } else {
        notif_error("Gagal!");
        console.error('ID tidak ditemukan dalam respons:', response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const afterPaste = async (data, coords) => {

    const token = await reloadToken();
    const config = apiConfig(token);

    const colHeaderList = hotInstance.getColHeader().length;
    hotInstance.selectCell(coords[0]['startRow'], 0, coords[0]['endRow'], colHeaderList - 1);
    const selected = hotInstance.getSelected() || [];
    const data_selected = [];

    setTimeout(async () => {
      for (let i = 0; i < selected.length; i += 1) {
        const item = selected[i];
        data_selected.push(hotInstance.getData(...item));
      }
      console.log(data_selected);
      console.log(JSON.stringify({ data_selected }));
      // Extract the first set of selected data
      const list = JSON.stringify(data_selected[0]);
      const formData = new FormData();
      formData.append('data', list);
      try {
        const response = await axios.post(`${hostizin}detail/paste`, formData, config);
        if (response.data.status === 'success') {
        } else {
          const message = response.data.message;
          console.log('Error:', message);
        }
        notif_success("Paste data sukses!");
        calculateSum();
      } catch (error) {
        console.error('Error:', error);

        notif_error("Gagal! ", error);
      }
    }, 500);
  };

  const handleAfterChange = async (changes, source) => {
    if (source === 'edit') {
      const token = await reloadToken();
      const config = apiConfig(token);
      setTimeout(async function () {
        const rowIndex = changes[0][0];
        const id = hotInstance.getDataAtCell(rowIndex, 0);

        const formData = new FormData();
        formData.append('no_spi', no_spi);
        formData.append('hs_code', hotInstance.getDataAtCell(rowIndex, 2));
        formData.append('uraian_brg', hotInstance.getDataAtCell(rowIndex, 3));
        formData.append('jumlah', hotInstance.getDataAtCell(rowIndex, 4));
        formData.append('satuan', hotInstance.getDataAtCell(rowIndex, 5));
        formData.append('asal_negara', hotInstance.getDataAtCell(rowIndex, 6));
        formData.append('negara_muat', hotInstance.getDataAtCell(rowIndex, 7));
        formData.append('pelabuhan_muat_kpbpb', hotInstance.getDataAtCell(rowIndex, 8));
        formData.append('pelabuhan_tujuan', hotInstance.getDataAtCell(rowIndex, 9));
        formData.append('keterangan', hotInstance.getDataAtCell(rowIndex, 10));

        try {
          const response = await axios.put(`${hostizin}detail/${id}`, formData, config);
          console.log("res ", response.data.status)
          if (response.data.status === true) {
            notif_success("Update data sukses!");
            calculateSum();
          } else {
            const message = response.data.message;
            console.log('Error:', message);
            notif_error('Error ', message);
          }
        } catch (error) {
          console.error('Error:', error);
          notif_error('Error ', error);
        }
      }, 100);
    }
  };

  const handleAfterRemoveRow = async (index, amount, physicalRows, [source]) => {
    if (v_status_proses == '1') {
      notif_error("Gagal menghapus data!. ");
      window.location.reload();
    } else {

      console.log("Table Data:", hotInstance.getData());

      const id = hotInstance.getDataAtCell(index, 0);

      console.log("ID:", id);

      const confirmed = window.confirm("Yakin akan menghapus data ini ?");
      if (!confirmed) {
        // hotInstance.undo();
        return;
      }

      const token = await reloadToken();
      console.log("token ", token)
      const config = apiConfig(token);
      try {
        const response = await axios.delete(`${hostizin}detail/${id}`, apiConfig(token));
        if (response.data.status === true) {
          // Handle success (if needed)
          notif_success("Data sukses di hapus!");
          calculateSum();
        } else {
          const message = response.data.message;
          console.log('Error:', message);
          notif_error("Data gagal di hapus ", message)
        }
      } catch (error) {
        console.error('Error:', error);
        notif_error("Data error ", error)
      }
    }
  };

  const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    // Use dangerouslySetInnerHTML to render HTML content
    if (typeof value === 'string') {
      td.innerHTML = value;
    }
  };

  const handleColumnResize = (size, column) => {
    localStorage.setItem(`${nama_pt}-${tipe}-${column}`, size);
  };

  const loadColumnWidthsFromLocalStorage = () => {
    const savedWidths = {};
    for (let i = 0; i < hotInstance.countCols(); i++) {
      const width = localStorage.getItem(`${nama_pt}-${tipe}-${i}`);
      savedWidths[i] = width ? parseInt(width) : 100;
    }
    hotInstance.updateSettings({
      colWidths: function (column) {
        return savedWidths[column];
      },
    });
  };


  const handleSavePublish = async () => {
    const isConfirmed = window.confirm('Pastikan data sudah di isi dengan benar, data publish tidak bisa di ubah kembali?');
    if (!isConfirmed) {
      return;
    }

    if (!v_namaPt || !v_noSpi || !v_jenis || !val_jumlah || !v_tanggal || !v_tanggalBerlaku || !v_tanggalBerakhir) {
      alert('Silahkan lengkapi data terlebih dahulu!');
      return;
    }

    setLoading(true);
    const token = await reloadToken();
    const config = apiConfig(token);

    const formData = new FormData();
    formData.append('code', "1"); //KODE 1 ADALAH ACTIVE

    try {
      const response = await axios.put(`${hostizin}updatestatus/${v_ID}`, formData, config);
      console.log('Data saved successfully:', response.data);
      notif_success("Simpan data sukses!. Data sudah bisa di gunakan");
      window.location.href = "/spi";
    } catch (error) {
      console.error('Error saving data:', error);
      notif_error('Error saving data:', error)
    } finally {
      setLoading(false);
    }
  };

  const backtoSpi = () => {
    window.location.href = "/spi";
  }

  const initializeForSearchColumn = () => {
    const debounceFn = (colIndex, event) => {
      const filtersPlugin = hotInstance.getPlugin('filters');
      filtersPlugin.removeConditions(colIndex);
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
      filtersPlugin.filter();
    };

    const addEventListeners = (input, colIndex) => {
      input.addEventListener('keydown', (event) => {
        debounceFn(colIndex, event);
      });
    };

    const addInput = (col, TH) => {
      if (typeof col !== 'number') {
        return col;
      }

      if (col >= 0 && TH.childElementCount < 2) {
        TH.appendChild(getInitializedElements(col));
      }
    };

    const getInitializedElements = (colIndex) => {
      const div = document.createElement('div');
      const input = document.createElement('input');
      addEventListeners(input, colIndex);
      div.appendChild(input);
      return div;
    };

    const doNotSelectColumn = (event, coords) => {
      if (coords.row === -1 && event.target.nodeName === 'INPUT') {
        event.stopImmediatePropagation();
        hotInstance.deselectCell();
      }
    };

    hotInstance.updateSettings({
      afterGetColHeader: addInput,
      beforeOnCellMouseDown: doNotSelectColumn,
    });
  }


  const handleInputChange = (event) => {
    setNewRowValue(event.target.value);
  };

  const handleAddRowClick = () => {
    const valueTotalinput = newRowValue;
    const hotInstance = hotRef.current.hotInstance;
    hotInstance.suspendRender();
    hotInstance.alter('insert_row_below', hotInstance.ending, 1);
    hotInstance.resumeRender();
  };

  const calculateSum = () => {
    const hotInstance = hotRef.current.hotInstance;
    const data = hotInstance.getData();  // Get all table data

    const sumBySatuan = {};

    data.forEach(row => {
      const jumlah = parseFloat(row[4]) || 0;  // Column 1 for jumlah
      const satuan = row[5];  // Column 2 for satuan
      if (satuan) {
        if (!sumBySatuan[satuan]) {
          sumBySatuan[satuan] = 0;
        }
        sumBySatuan[satuan] += jumlah;
      }
    });

    console.log("Sum ", sumBySatuan)
    setGroupedSum(sumBySatuan);
  };

  const handleClick = (data) => {
    if (data == "role") {
      window.location.href = "/role";
    } else if (data == "role_detail") {
      window.location.href = "/roledetail";
    } else if (data == "masteruser") {
      window.location.href = "/masteruser";
    }
  };

  const columns = [
    { type: 'text', title: 'ID', align: 'center', readOnly: true },
    { type: 'text', title: '<b>NO SPI</b>', wordWrap: false, align: 'center', readOnly: true },
    { type: 'text', title: '<b>NO POST TARIF / HS</b>', wordWrap: false, align: 'center', readOnly: v_status_proses === '1' ? true : false },
    { type: 'text', title: '<b>URAIAN BARANG</b>', wordWrap: false, align: 'center', readOnly: v_status_proses === '1' ? true : false },
    { type: 'text', title: '<b>JUMLAH</b>', wordWrap: false, readOnly: v_status_proses === '1' ? true : false },
    { type: 'dropdown', title: '<b>SATUAN</b>', wordWrap: false, readOnly: v_status_proses === '1' ? true : false, source: ['Metrik Ton', 'Piece', 'Kilogram', 'Meter', 'Gram'] },
    { type: 'text', title: '<b>NEGARA ASAL</b>', wordWrap: false, readOnly: v_status_proses === '1' ? true : false },
    { type: 'text', title: '<b>NEGARA MUAT</b>', wordWrap: false, readOnly: v_status_proses === '1' ? true : false },
    { type: 'text', title: '<b>PELABUHAN MUAT DI KPBPB</b>', wordWrap: false, readOnly: v_status_proses === '1' ? true : false },
    { type: 'text', title: '<b>PELABUHAN TUJUAN</b>', wordWrap: false, readOnly: v_status_proses === '1' ? true : false },
    { type: 'text', title: '<b>KETERANGAN</b>', wordWrap: false, readOnly: v_status_proses === '1' ? true : false },
  ];

  const selectTitleComponent = {
    padding: '4px',
    margin: '5px',
    fontWeight: 'bold'
  }

  return (
    <div>
      <HeaderMenu title="PERSETUJUAN IMPOR" subtitle="Halaman untuk data Kontainer" />
      <ToastContainer />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      <div className={styles.tableContainer}>
        <div className={styles.tableContainerchild}>
          <div className={styles.inputFieldWrapper}>

            <span className={styles.formtitle}>
              <img src='../../images/icon/doc-image.png' height={25} width={20} style={{ marginRight: '10px' }}></img>
              DATA SPI PERUSAHAAN
            </span>
            <table className={styles.infoTable}>
              <tr> <td><label className={styles.label}>Nama PT </label></td><td>:</td><td><span className={styles.textValue}>{v_namaPt}</span></td></tr>
              <tr> <td><label className={styles.label}>No SPI </label></td><td>:</td><td><span className={styles.textValue}>{v_noSpi}</span></td></tr>
              <tr> <td><label className={styles.label}>Jenis </label></td><td>:</td><td><span className={styles.textValue}>{v_jenis}</span></td></tr>
              <tr> <td><label className={styles.label}>Tanggal </label></td><td>:</td><td><span className={styles.textValue}>{formatDate(v_tanggal)}</span></td></tr>
              <tr> <td><label className={styles.label}>Tanggal Berlaku</label></td><td>:</td><td><span className={styles.textValue}>{formatDate(v_tanggalBerlaku)}</span></td></tr>
              <tr> <td><label className={styles.label}>Tanggal Berakhir</label></td><td>:</td><td><span className={styles.textValue}>{formatDate(v_tanggalBerakhir)}</span></td></tr>
              <tr> <td><label className={styles.label}>Status Proses</label></td><td>:</td><td><span className={styles.textValue}>{v_nama_status_proses}</span></td></tr>
              <tr> <td><label className={styles.label}>TOTAL</label></td><td>:</td><td>              <span className={styles.textValue}>
                {Object.entries(groupedSum).map(([satuan, total], index) => (
                  <span key={satuan}>
                    {satuan}: {total}
                    {index < Object.entries(groupedSum).length - 1 ? ' | ' : ''}
                  </span>
                ))}
              </span></td></tr>
            </table>

          </div>
        </div>

        <div className={styles.tableContainerchild}>
          <span className={styles.formtitle}>
            <img src='../../images/icon/doc-image.png' height={25} width={20} style={{ marginRight: '10px' }}></img>
            DATA DETAIL SPI
          </span>

          <div className={styles.buttoncell} style={{ textAlign: 'left' }}>
            <div id="footer_data" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>

              {v_status_proses !== '1' ? (
                <>
                  <button className={styles.btnNewCell} type="button" onClick={handleAddRowClick}>+ New Row</button>
                  <button className={styles.btnpublich} type="button" onClick={handleSavePublish}>Save & Publish</button>
                </>
              ) : null}

              <button className={styles.btnback} type="button" onClick={backtoSpi}>Back</button>
            </div>
          </div>


          <HotTable
            ref={hotRef}
            settings={{
              afterCreateRow: handleAfterCreateRow,
              afterChange: handleAfterChange,
              beforeRemoveRow: handleAfterRemoveRow,
              afterPaste: afterPaste,
              stretchH: 'all',
              autoColumnSize: true,
            }}
            autoRowSize={{ syncLimit: '100%' }}
            renderer={customRenderer}
            contextMenu={true}
            rowHeaders={true}
            filters={true}
            outsideClickDeselects={false}
            allowInsertColumn={true}
            height={tableHeight}
            width="100%"
            readOnly={false}
            manualColumnResize={true}
            dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
            columns={columns}
            licenseKey="non-commercial-and-evaluation"
            hiddenColumns={{
              copyPasteEnabled: true,
              indicators: true,
              columns: [0, 1]
            }}
          />

        </div>
      </div>


    </div>

  );
};

export default SpiADD;