// modal.js
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import '../css/Modal.css';
import axios from 'axios';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import styleHs from '../css/Hs.module.css';
import reloadToken from '../helper/reloadtoken';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify_cant_update_br_cont = (message, time = 500) => {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: time,
    });
};

const notify_update_br_cont = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 800,
    });
};

const Modal = ({ modalVisible, toggleModal, data }) => {
    const [tableHeight, setTableHeight] = useState(550);
    const hotRef = useRef(null);
    const hotRefDetail = useRef(null);
    const isMounted = useRef(true);
    const [hotInstance, setHotInstance] = useState(null);
    const [hotInstanceDetail, setHotInstanceDetail] = useState(null);
    const [newRowValue, setNewRowValue] = useState('');
    const [colHeaders, setColHeaders] = useState([]);
    const hostcont = process.env.REACT_APP_API_HOST_DATA_CONT;
    const hostfile = process.env.REACT_APP_API_HOST_UPLOAD;
    var data_all = [];

    const loadData = async () => {
        try {
            const id = data.id;
            const config = apiConfig(data.token);
            const response = await axios.post(`${hostcont}ReactBrcontainersFile`, { id }, config);
            return response;
        } catch (error) {
            console.error('Error fetching column names:', error);
            return [];
        }
    };

    const createFile = async (token, iddata) => {
        try {
            const config = apiConfig(token);
            console.log(iddata)
            const response = await axios.post(`${hostcont}CreateNasFile/br`, { iddata }, config);
            return response;
        } catch (error) {
            console.error('Error fetching column names:', error);
            return [];
        }
    };


    const fetchData = async () => {
            const res = await loadData();
            if (hotRefDetail.current && data.token && isMounted.current) {
                const hot100 = hotRefDetail.current.hotInstance;
                //setHotInstanceDetail(hot100);
                if (res && Array.isArray(res.data.datadetail)) {
                    if (hotRefDetail.current.hotInstance) {
                        hotRefDetail.current.hotInstance.loadData(res.data.datadetail);
                    } else {
                        console.error("Hot instance is null or undefined");
                    }
                } else {
                   // console.error("Invalid data format or data is missing:", res);
                }
            }
    };

    const fetchData2 = async () => {
            const res = await loadData(data.token, data.id);
            if (hotRef.current && data.token && isMounted.current) {
                const hot = hotRef.current.hotInstance;
                //setHotInstance(hot);
                if (res && Array.isArray(res.data.data)) {
                    if (hotRef.current.hotInstance) {
                        hotRef.current.hotInstance.loadData(res.data.data);
                        initializeForSearchColumn();
                        initializeForSearchColumnDetail();
                    } else {
                        console.error("Hot instance is null or undefined");
                    }
                } else {
                   // console.error("Invalid data format or data is missing:", res);
                }
            }
    }

    const reloadAll = () => {
        fetchData();
        fetchData2();
    }

    const isDataEmpty = !data || !data.nama;
    
    useEffect(() => {
        console.log("Modal visible:", modalVisible);
        let interval;
        if (modalVisible) {
            console.log("Opening modal...");
            document.body.classList.add('active-modal');
            reloadAll();
    
            interval = setInterval(() => {
                console.log("Reloading data...");
                reloadAll();
            }, 2000);
        }
    
        return () => {
            console.log("Closing modal or unmounting...");
            clearInterval(interval);
            document.body.classList.remove('active-modal');
        };
    }, [modalVisible]);
    

    // 
    // if (modalVisible) {
    //     document.body.classList.add('active-modal');
    //     reloadAll();
    // } else {
    //     document.body.classList.remove('active-modal');
    // }

    const closeModal = () => {
        toggleModal();
    };

    const uploadModal = () => {
        createFile(data.token, data.id)
    };

    const downloadModal = () => {
        alert('Download');
    };

    const downloadPacketFile = () => {
        downloadPacketFile_(data.id);
    };

    const downloadPacketFile_ = async (id) => {
        const newToken = await reloadToken();
        try {
            const response = await axios.post(
                `${hostcont}Downloapacketfilepkpriok/${id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${newToken}`,
                    },
                }
            );
            if (response.data.data) {
                window.open(response.data.data, '_blank');
            } else {
                console.error('Invalid response type or data:', response);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            notify_cant_update_br_cont("File belum tersedia.");
        }
    };
    
    // const downloadPacketFile_ = async (id) => {
    //     const newToken = await reloadToken();
    //     try {
    //         // Make a POST request to download the file
    //         const response = await axios.post(
    //             `${hostcont}Downloapacketfilepkpriok/${id}`,
    //             {}, // Empty object for POST request body
    //             {
    //                 responseType: 'blob', // Treat response as a binary blob
    //                 headers: {
    //                     Authorization: `Bearer ${newToken}`, // Include authentication token
    //                 },
    //             }
    //         );
    
    //         // Extract filename from Content-Disposition header
    //         const contentDisposition = response.headers['content-disposition'];
    //         const filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
    
    //         // Create a blob from the response data and generate URL for it
    //         const blob = new Blob([response.data], { type: 'application/zip' });
    //         const url = window.URL.createObjectURL(blob);
    
    //         // Create a temporary <a> element, set its attributes, trigger download, and clean up
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = filename; 
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         // Handle errors
    //         console.error('Error downloading file:', error);
    //         notify_cant_update_br_cont("File belum tersedia.");
    //     }
    // };
    

    const handleAfterCreateRow = async (index) => {
        // var i = parseInt(index);
        // try {
        //   const response = await axios.post(`${hostuser}Role/New`, null, apiConfig(token));
        //   if (response.data.status === 'success') {
        //     hotInstance.setDataAtCell(i, 0, response.data.data);
        //   } else {
        //     console.log('Error:', response.data.message);
        //   }
        // } catch (error) {
        //   console.error('Error:', error);
        // }
    };

    const handleAfterChange = async (changes, source) => {
        if (source === 'edit') {
            let old_data = changes[0][2];
            let new_data = changes[0][3];
            if (old_data != new_data) {
                setTimeout(async function () {
                    const rowIndex = changes[0][0];
                    const hotInstance_ = hotRef.current.hotInstance;
                    const id = hotInstance_.getDataAtCell(rowIndex, 0);

                    console.log("changes ", id)
                    const datapost = {
                        "msg": hotInstance_.getDataAtCell(rowIndex, 3),
                        "col": hotInstance_.getDataAtCell(rowIndex, 4),
                        "tipe": hotInstance_.getDataAtCell(rowIndex, 5),
                    };
                    try {
                        const response = await axios.post(`${hostfile}Updatecomment/${id}`, datapost, apiConfig(data.token));
                        if (response.data.status === 'success') {
                            notify_update_br_cont(response.data.message)
                        } else {
                            const message = response.data.message;
                            console.log('Error:', message);
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }, 100);
            }
        }
    };

    const handleAfterChangedetail = async (changes, source) => {
        if (source === 'edit') {
            let old_data = changes[0][2];
            let new_data = changes[0][3];
            if (old_data != new_data) {
                setTimeout(async function () {
                    const rowIndex = changes[0][0];

                    const hotInstanceDetail_ = hotRefDetail.current.hotInstance;
                    const id = hotInstanceDetail_.getDataAtCell(rowIndex, 0);

                    console.log("changes ", hotInstanceDetail_.getDataAtCell(rowIndex, 3))
                    const datapost = {
                        "msg": hotInstanceDetail_.getDataAtCell(rowIndex, 4),
                        "col": hotInstanceDetail_.getDataAtCell(rowIndex, 5),
                        "tipe": hotInstanceDetail_.getDataAtCell(rowIndex, 6),
                    };
                    try {
                        const response = await axios.post(`${hostfile}Updatecomment/${id}`, datapost, apiConfig(data.token));
                        if (response.data.status === 'success') {
                            notify_update_br_cont(response.data.message)
                        } else {
                            const message = response.data.message;
                            console.log('Error:', message);
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }, 100);
            }
        }
    };

    const handleAfterRemoveRow = async (index, amount, physicalRows, [source]) => {
        // const id = hotInstance.getDataAtCell(index, 0);
        // try {
        //   const response = await axios.post(`${hostuser}Role/${id}`, null, apiConfig(token));
        //   if (response.data.status === 'success') {

        //   } else {
        //     const message = response.data.message;
        //     console.log('Error:', message);
        //   }
        // } catch (error) {
        //   console.error('Error:', error);
        // }
    };

    const handleInputChange = (event) => {
        //setNewRowValue(event.target.value);
    };

    const handleAddRowClick = () => {
        const valueTotalinput = newRowValue;
        const hotInstance = hotRef.current.hotInstance;
        hotInstance.suspendRender();
        hotInstance.alter('insert_row_below', hotInstance.ending, 1);
        hotInstance.resumeRender();
    };

    const handleClick = (data) => {
        if (data == "role") {
            window.location.href = "/role";
        } else if (data == "role_detail") {
            window.location.href = "/roledetail";
        } else if (data == "masteruser") {
            window.location.href = "/masteruser";
        }
    };

    const initializeForSearchColumn = () => {
        const debounceFn = (colIndex, event) => {
            const filtersPlugin = hotInstance.getPlugin('filters');
            filtersPlugin.removeConditions(colIndex);
            filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
            filtersPlugin.filter();
        };

        const addEventListeners = (input, colIndex) => {
            input.addEventListener('keydown', (event) => {
                debounceFn(colIndex, event);
            });
        };

        const addInput = (col, TH) => {
            if (typeof col !== 'number') {
                return col;
            }

            if (col >= 0 && TH.childElementCount < 2 && col == 1) {
                TH.appendChild(getInitializedElements(col));
            }
        };

        const getInitializedElements = (colIndex) => {
            const div = document.createElement('div');
            const input = document.createElement('input');
            addEventListeners(input, colIndex);
            div.appendChild(input);
            return div;
        };

        const doNotSelectColumn = (event, coords) => {
            if (coords.row === -1 && event.target.nodeName === 'INPUT') {
                event.stopImmediatePropagation();
                hotInstance.deselectCell();
            }
        };
    }

    const initializeForSearchColumnDetail = () => {
        const debounceFn = (colIndex, event) => {
            const filtersPlugin = hotInstanceDetail.getPlugin('filters');
            filtersPlugin.removeConditions(colIndex);
            filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
            filtersPlugin.filter();
        };

        const addEventListeners = (input, colIndex) => {
            input.addEventListener('keydown', (event) => {
                debounceFn(colIndex, event);
            });
        };

        const addInput = (col, TH) => {
            if (typeof col !== 'number') {
                return col;
            }

            if (col >= 0 && TH.childElementCount < 2 && col <= 2) {
                TH.appendChild(getInitializedElements(col));
            }
        };

        const getInitializedElements = (colIndex) => {
            const div = document.createElement('div');
            const input = document.createElement('input');
            addEventListeners(input, colIndex);
            div.appendChild(input);
            return div;
        };

        const doNotSelectColumn = (event, coords) => {
            if (coords.row === -1 && event.target.nodeName === 'INPUT') {
                event.stopImmediatePropagation();
                hotInstanceDetail.deselectCell();
            }
        };
    }

    const columns = [
        { type: 'text', title: 'ID', align: 'center' },
        { type: 'text', title: '<b>Nama File</b>', editor: false, align: 'center' },
        { type: 'text', title: '<b>Upload</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Catatan</b>', className: 'htCenter' },
        { type: 'text', title: '<b>name column</b>', className: 'htCenter' },
        { type: 'text', title: '<b>tipe column</b>', className: 'htCenter' },
        { type: 'text', title: '<b>View</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Downld</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Del</b>', editor: false, className: 'htCenter', renderer: 'html' },
    ];
    const columnsdetail = [
        { type: 'text', title: 'ID', align: 'center' },
        { type: 'text', title: '<b>Nama File</b>', editor: false, align: 'center' },
        { type: 'text', title: '<b>Container</b>', editor: false, align: 'center' },
        { type: 'text', title: '<b>Upload</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Catatan</b>', className: 'htCenter' },
        { type: 'text', title: '<b>name column</b>', className: 'htCenter' },
        { type: 'text', title: '<b>tipe column</b>', className: 'htCenter' },
        { type: 'text', title: '<b>View</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Downld</b>', editor: false, className: 'htCenter', renderer: 'html' },
        { type: 'text', title: '<b>Del</b>', editor: false, className: 'htCenter', renderer: 'html' },
    ];

    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        // Use dangerouslySetInnerHTML to render HTML content
        if (typeof value === 'string') {
            td.innerHTML = value;
        }
    };

    return createPortal(
        modalVisible ? (
            <div className="modal">
                <div onClick={closeModal} className="overlay"></div>
                <div className="modal-content">
                <button className="btn_close-modal btn_close close-botton-top" onClick={closeModal}>
                            Close
                </button>
                    <div className="flex-container">
                        <div className="flex-item">
                            {isDataEmpty ? (<p>Error, data is empty</p>) : (<div><h6>Data File Container</h6><p style={{ marginTop: '-5px' }}>Upload / Download sesuaikan dengan nama list file</p></div>)}
                            <div>
                                <HotTable
                                    ref={hotRef}
                                    columns={columns}
                                    settings={{
                                        afterCreateRow: handleAfterCreateRow,
                                        afterChange: handleAfterChange,
                                        beforeRemoveRow: handleAfterRemoveRow
                                    }}
                                    renderer={customRenderer}
                                    contextMenu={true}
                                    rowHeaders={true}
                                    filters={true}
                                    outsideClickDeselects={false}
                                    allowInsertColumn={true}
                                    rowHeights={25}
                                    autoRowSize={false}
                                    manualColumnResize={true}
                                    height={420}
                                    width={705}
                                    dropdownMenu={false}
                                    colHeaders={colHeaders}
                                    colWidths={[0, 125, 120, 127, 100, 0, 80, 70, 70, 40]}
                                    licenseKey="non-commercial-and-evaluation"
                                    hiddenColumns={{
                                        copyPasteEnabled: true,
                                        indicators: true,
                                        columns: [0, 4, 5]
                                    }}
                                >
                                    {colHeaders.map((colName, index) => (
                                        <HotColumn key={index} renderer={"html"} editor={false} />
                                    ))}
                                </HotTable>
                            </div>
                        </div>

                        <p></p>
                        <div className="flex-item">
                            {isDataEmpty ? (<p>Error, data is empty</p>) : (<div><h6>Data File FCL</h6><p style={{ marginTop: '-5px' }}>Upload / Download sesuaikan dengan nama list file</p></div>)}
                            <div>
                                <HotTable
                                    ref={hotRefDetail}
                                    columns={columnsdetail}
                                    settings={{
                                        afterCreateRow: handleAfterCreateRow,
                                        afterChange: handleAfterChangedetail,
                                        beforeRemoveRow: handleAfterRemoveRow
                                    }}
                                    data={data_all}
                                    renderer={customRenderer}
                                    contextMenu={true}
                                    rowHeaders={true}
                                    filters={true}
                                    outsideClickDeselects={false}
                                    allowInsertColumn={true}
                                    rowHeights={25}
                                    autoRowSize={false}
                                    manualColumnResize={true}
                                    height={420}
                                    width={705}
                                    dropdownMenu={false}
                                    colHeaders={colHeaders}
                                    colWidths={[0, 125, 120, 127, 100, 0, 80, 70, 70, 40]}
                                    licenseKey="non-commercial-and-evaluation"
                                    hiddenColumns={{
                                        copyPasteEnabled: true,
                                        indicators: false,
                                        columns: [0, 5, 6]
                                    }}
                                >
                                    {colHeaders.map((colName, index) => (
                                        <HotColumn key={index} renderer={"html"} editor={false} />
                                    ))}
                                </HotTable>
                            </div>
                            <p> </p></div>
                    </div>

                    <div className='packbutton'>
                        <button className="btn_close-modal btn_close" onClick={closeModal}>
                            Close
                        </button>
                        <button className="btn_close-modal btn_upload" onClick={uploadModal}>
                            NAS Folder
                        </button>
                        {data.role == "PK_PRIOK" ? (
                            <button className="btn_close-modal btn_download" onClick={downloadPacketFile}>
                                Download all
                            </button>
                        ) : ""}
                    </div>
                </div>
            </div>
        ) : null,
        document.body
    );
};

export default Modal;