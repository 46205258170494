import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable';

import Header from '../../template/HeaderMenu';
import styleHs from '../../css/Hs.module.css';
import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import styles from '../../css/Hs.module.css';
import '../../css/new.css';

const notif_error = (message, time = 1500) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: time,
  });
};

const notif_success = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 800,
  });
};


registerAllModules();
const hostuser = process.env.REACT_APP_API_HOST_MASTER_USER
const hostLaporan = process.env.REACT_APP_API_HOST_LAPORAN;

const StuffingErika = () => {
    const [data, setData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [selectedRows, setSelectedRows] = useState(new Set());
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 1;
    const startYear = 2025;
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    const months = [
        { value: '01', label: 'Januari' },
        { value: '02', label: 'Februari' },
        { value: '03', label: 'Maret' },
        { value: '04', label: 'April' },
        { value: '05', label: 'Mei' },
        { value: '06', label: 'Juni' },
        { value: '07', label: 'Juli' },
        { value: '08', label: 'Agustus' },
        { value: '09', label: 'September' },
        { value: '10', label: 'Oktober' },
        { value: '11', label: 'November' },
        { value: '12', label: 'Desember' },
    ];


    const [tableHeight, setTableHeight] = useState(350);
    const hotRef = useRef(null);
    const [hotInstance, setHotInstance] = useState(null);
    const [token, setToken] = useState(null);
    const [newRowValue, setNewRowValue] = useState('');
    let nama_pt = "ROLE";
    let tipe = "1";

    useEffect(() => {
        const setUP = async () => {
            const hot = hotRef.current.hotInstance;
            setHotInstance(hot);
            const token = await reloadToken();
            setToken(token);
        }
        setUP();
        const screenHeight = window.innerHeight;
        const headerHeight = 70;
        const footerHeight = 70;
        const calculatedHeight = screenHeight - headerHeight - footerHeight;
        setTableHeight(calculatedHeight);
    }, []);


    const handleSubmit = async () => {
        setLoading(true);
        if (!selectedMonth || !selectedYear) {
            alert('Pilih bulan dan tahun terlebih dahulu.');
            return;
        }
        try {
            const token = await reloadToken();
            const config = apiConfig(token);
            const payload = { bln: parseInt(selectedMonth, 10), thn: parseInt(selectedYear, 10) };

            if (hotInstance && token) {
                const res = await axios.post(`${hostLaporan}stuffingallHSErk`, payload, config);
                setData(res.data);
                hotInstance.addHook('afterColumnResize', handleColumnResize);
                loadColumnWidthsFromLocalStorage();
                initializeForSearchColumn();
                if (res && Array.isArray(res.data)) {
                    hotInstance.loadData(res.data);
                } else {
                    console.error("Invalid data format or data is missing:", res);
                }

                updateWarnaTanda();

            }

            setError(null);  // Reset error
        } catch (err) {
            setError(err.message || 'Failed to fetch data');
            console.error('Error fetching data:', err);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const initializeForSearchColumn = () => {
        const debounceFn = (colIndex, event) => {
            const filtersPlugin = hotInstance.getPlugin('filters');
            filtersPlugin.removeConditions(colIndex);
            filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
            filtersPlugin.filter();
        };

        const addEventListeners = (input, colIndex) => {
            input.addEventListener('keydown', (event) => {
                debounceFn(colIndex, event);
            });
        };

        const addInput = (col, TH) => {
            if (typeof col !== 'number') {
                return col;
            }

            if (col >= 0 && TH.childElementCount < 2) {
                TH.appendChild(getInitializedElements(col));
            }
        };

        const getInitializedElements = (colIndex) => {
            const div = document.createElement('div');
            const input = document.createElement('input');
            addEventListeners(input, colIndex);
            div.appendChild(input);
            return div;
        };

        const doNotSelectColumn = (event, coords) => {
            if (coords.row === -1 && event.target.nodeName === 'INPUT') {
                event.stopImmediatePropagation();
                hotInstance.deselectCell();
            }
        };

        hotInstance.updateSettings({
            afterGetColHeader: addInput,
            beforeOnCellMouseDown: doNotSelectColumn,
        });
    }

    const handleSaveButtonClick = () => {
        const config = apiConfig(token);
        var requestData = { data: hotInstance.getData() }; // No need to stringify
        var alertConfirm = window.confirm("Data akan di proses?");
        if (alertConfirm) {
            axios.post(`${hostuser}saveBiaya/PIB`, requestData, config)
                .then(response => {
                    if (response.data.status === 'success') {
                        hotInstance.loadData([]);
                        var data = response.data.data;
                        var kode = response.data.group;
                        window.location.href = `/pibdetail/pib/${kode}`;
                        console.log('data:', data);
                        console.log('Kode:', kode);
                    } else {
                        var message = response.data.message;
                        console.log('Error:', message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error.response.data.message);
                });
        }
    };

    const loadColumnWidthsFromLocalStorage = () => {
        const savedWidths = {};
        for (let i = 0; i < hotInstance.countCols(); i++) {
            const width = localStorage.getItem(`${nama_pt}-${tipe}-${i}`);
            savedWidths[i] = width ? parseInt(width) : 100;
        }
        hotInstance.updateSettings({
            colWidths: function (column) {
                return savedWidths[column];
            },
        });
    };

    const handleColumnResize = (size, column) => {
        localStorage.setItem(`${nama_pt}-${tipe}-${column}`, size);
    };

    const handleInputChange = (event) => {
        setNewRowValue(event.target.value);
    };

    const handleAddRowClick = () => {
        const valueTotalinput = newRowValue;
        const hotInstance = hotRef.current.hotInstance;
        hotInstance.suspendRender();
        hotInstance.alter('insert_row_below', hotInstance.ending, 1);
        hotInstance.resumeRender();
    };

    const handleClick = (data) => {
        if (data == "role") {
            window.location.href = "/role";
        } else if (data == "role_detail") {
            window.location.href = "/roledetail";
        } else if (data == "masteruser") {
            window.location.href = "/masteruser";
        }
    };


    const handleAfterCreateRow = async (index) => {
        var i = parseInt(index);
        try {
            const response = await axios.post(`${hostuser}Role/New`, null, apiConfig(token));
            if (response.data.status === 'success') {
                hotInstance.setDataAtCell(i, 0, response.data.data);
            } else {
                console.log('Error:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const afterPaste = async (data, coords) => {
        const token = await reloadToken();
        const config = apiConfig(token);
    
        const colHeaderList = hotInstance.getColHeader().length;
        hotInstance.selectCell(coords[0]['startRow'], 0, coords[0]['endRow'], colHeaderList - 1);
        const selected = hotInstance.getSelected() || [];
        const data_selected = [];
    
        setTimeout(async () => {
          for (let i = 0; i < selected.length; i += 1) {
            const item = selected[i];
            data_selected.push(hotInstance.getData(...item));
          }
          console.log(data_selected);
          console.log(JSON.stringify({ data_selected }));
          // Extract the first set of selected data
          const list = JSON.stringify(data_selected[0]);
          const formData = new FormData();
          formData.append('data', list);
          try {
            const response = await axios.post(`${hostLaporan}tandaipaste`, formData, config);
            if (response.data.status === 'success') {
            } else {
              const message = response.data.message;
              console.log('Error:', message);
            }
            notif_success("Paste data sukses!");
          } catch (error) {
            console.error('Error:', error);
    
            notif_error("Gagal! ", error);
          }
          updateWarnaTanda();
        }, 500);
      };
    
      const handleAfterChange = async (changes, source) => {
        if (source === 'edit') {
          const token = await reloadToken();
          const config = apiConfig(token);
          setTimeout(async function () {
            const rowIndex = changes[0][0];
            const id = hotInstance.getDataAtCell(rowIndex, 0);
    
            const formData = new FormData();
            formData.append('id_cont', id);
            formData.append('tandai', hotInstance.getDataAtCell(rowIndex, 10));
    
            try {
              const response = await axios.post(`${hostLaporan}tandaisave`, formData, config);
              console.log("res ", response.data.status)
              if (response.data.status === true) {
                notif_success("Update data sukses!");
              } else {
                const message = response.data.message;
                console.log('Error:', message);
                notif_error('Error ', message);
              }
            } catch (error) {
              console.error('Error:', error);
              notif_error('Error ', error);
            }
            updateWarnaTanda();
          }, 100);
        }
      };

    const updateWarnaTanda = () => {
        if (hotInstance) {
        hotInstance.updateSettings({
            cells: (row, col) => {
              const cellProperties = {};
              
              // Ambil nilai dari kolom 10
              const tandaValue = hotInstance.getDataAtCell(row, 10);
              if (tandaValue) {
                // Tentukan renderer untuk kolom yang sesuai
                cellProperties.renderer = function(instance, td, row, col, prop, value, cellProperties) {
                  Handsontable.renderers.TextRenderer.apply(this, arguments); // Render teks default
                  
                  // Tentukan warna latar belakang berdasarkan nilai dari kolom 10
                  td.style.backgroundColor = '#9ad7ff'; // Warna latar belakang yang diinginkan
                };
              }
    
              return cellProperties;
            }
          });
        }
    }  

    const columns = [
        { type: 'text', title: 'ID', align: 'center', readOnly: true },
        { type: 'text', title: '<b>LOAD DATE</b>', wordWrap: false, align: 'center', readOnly: true },
        { type: 'text', title: '<b>GPS</b>', wordWrap: false, align: 'center', readOnly: true },
        { type: 'text', title: '<b>CTNS</b>', wordWrap: false, readOnly: true },
        { type: 'text', title: '<b>PORT</b>', wordWrap: false, readOnly: true },
        { type: 'text', title: '<b>ATA</b>', wordWrap: false, readOnly: true },
        { type: 'text', title: '<b>KET</b>', wordWrap: false, readOnly: true },
        { type: 'text', title: '<b>LINE</b>', wordWrap: false, readOnly: true },
        { type: 'text', title: '<b>CONTAINER NO</b>', wordWrap: false, readOnly: true },
        { type: 'text', title: '<b>QTY</b>', wordWrap: false, readOnly: true },
        { type: 'text', title: '<b>TANDAI</b>' },
    ];
    const colHeaders = columns.map(column => column.title);

    const selectStyle = {
        // Define your custom CSS styles here
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '4px',
        fontSize: '16px',
        width: 'auto',
        height: '10%',
    };

    const selectTitleComponent = {
        padding: '4px',
        margin: '5px',
        fontWeight: 'bold'
    }

    const cellStyle = {
        backgroundColor: 'yellow', // Ganti dengan warna atau gaya lainnya
      };


    return (
        <div>
            <Header title="DATA CONTAINER STUFFING" />
            <div>
                <div className="row valign-wrapper">
                    <div className="input-field col s4">
                        <span style={selectTitleComponent}>CARI DATA :</span>
                        <select style={selectStyle}
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                        >
                            <option value="" disabled>Pilih Bulan</option>
                            {months.map((month) => (
                                <option key={month.value} value={month.value}>{month.label}</option>
                            ))}
                        </select>

                        <select style={selectStyle}
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                        >
                            <option value="" disabled>Pilih Tahun</option>
                            {years.map((year) => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>

                        <button className="btn_kirim" onClick={handleSubmit}>TAMPILKAN DATA</button>

                    </div>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}

                <div className={styles.hotTableWrapper}>
                    <HotTable
                        ref={hotRef}
                        settings={{
                            afterChange: handleAfterChange,
                            afterPaste: afterPaste,
                            stretchH: 'all',
                            autoColumnSize: true,
                          }}
                        autoRowSize={{ syncLimit: '100%' }}
                        contextMenu={true}
                        rowHeaders={true}
                        filters={true}
                        outsideClickDeselects={false}
                        allowInsertColumn={true}
                        height={tableHeight}
                        width="100%"
                        readOnly={false}
                        manualColumnResize={true}
                        dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
                        columns={columns}
                        
                        licenseKey="non-commercial-and-evaluation"
                        hiddenColumns={{
                            copyPasteEnabled: true,
                            indicators: true,
                             columns: [0]
                        }}
                        

                    />
                </div>


                {/* <div className="positionfix" style={{ textAlign: 'left' }}>
            <div id="footer_data" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>
            <input className={`${styleHs.inputText}`} id="new_add_row" value={newRowValue} onChange={handleInputChange} type="text" placeholder="0"size="3" maxLength="2"/>
            <button className={`${styleHs.button} ${styleHs.buttonnew}`} id="btn_save" onClick={() => handleAddRowClick()}>
               +  Add New Row
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("role")}>
              Role
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("role_detail")}>
              Role Detail
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("masteruser")}>
              Master User
            </button>
            </div>
        </div> */}

            </div>
        </div>
    );
};

export default StuffingErika;