import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'select2';
import '../../css/Login.css';
import '../../css/Materialize.css';
import '../../css/Style.css';
import '../../css/new.css';
import reloadToken from '../../helper/reloadtoken';
import helperFilter from '../../helper/helperfilter';
import { dataconfig, showtoolbarConfig, ct, dataconfigfclpage } from '../../helper/luckysheetHelper';
import { hide, bikinInfoSum, sumDataKerja, getFrozen, createRatioZoom, getRatioZoom, saveheighrow, getheighrow, bikinselect, getFilterPageAll } from '../../helper/hide';
import HeaderMenu from '../../template/HeaderMenu';
import { apiConfig } from '../../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dataformula from '../../sheetFormula';
import pako from 'pako';
import { Autofill } from 'handsontable/plugins';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import './LaporanStuffing.css';  // Custom CSS file for styling

const tipedata = "FCL";
const hostLaporan = process.env.REACT_APP_API_HOST_LAPORAN;

const fullURL = window.location.href;


const notify_cant_update_br_cont = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 500,
    });
};
const notify_update_br_cont = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 500,
    });
};

const LaporanStuffing = () => {
    const [data, setData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = await reloadToken();
            const config = apiConfig(token);
            const response = await axios.post(`${hostLaporan}stuffingadm`, null, config);
            setData(response.data);
            setError(null);  // Reset error
        } catch (err) {
            setError(err.message || 'Failed to fetch data');
            console.error('Error fetching data:', err);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const columns = React.useMemo(
        () => [
            { Header: 'Tgl Stuffing', accessor: 'tgl_stufing' },
            { Header: 'Load Date', accessor: 'load_date' },
            { Header: 'ETA Date', accessor: 'eta_date' },
            { Header: 'Jalur', accessor: 'jalur' },
            { Header: 'Qty', accessor: 'qty' },
            { Header: 'Marking', accessor: 'marking' },
            { Header: 'Cnee', accessor: 'cnee' },
            { Header: 'Item', accessor: 'item' },
            { Header: 'Group Commodity', accessor: 'group_commodity' },
            { Header: 'Commodity', accessor: 'commodity' },
            { Header: 'No Cont', accessor: 'no_cont' },
            { Header: 'UK', accessor: 'uk' },
            { Header: 'Port', accessor: 'port' },
            { Header: 'Sales', accessor: 'sales' },
            { Header: 'Admin', accessor: 'admin' },
            { Header: 'Admin Input', accessor: 'admin_input' },
            { Header: 'Tipe Trans', accessor: 'tipe_trans' },
            { Header: 'Custom', accessor: 'custom' },
            { Header: 'Kapal', accessor: 'kapal' },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter: setTableGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter, useSortBy);

    // Handle global filter input
    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setGlobalFilter(value);
        setTableGlobalFilter(value);
    };

    const subtotalQty = rows.reduce((total, row) => {
        const qtyValue = row?.values?.qty ?? 0;  // Nullish coalescing untuk menghindari undefined
        return total + (Number(qtyValue) || 0);
      }, 0);


      return (
        <div>
            <HeaderMenu title="DATA STUFFING HARIAN" subtitle="Halaman untuk data Kontainer" />
            <ToastContainer />

            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search..."
                    value={globalFilter}
                    onChange={handleFilterChange}
                    className="search-input"
                />
            </div>
    
          <div className="table-container">
            <table {...getTableProps()} className="custom-table">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length}>No data available</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4} style={{ textAlign: 'right' }}>Subtotal Qty:</td>
                  <td>{subtotalQty}</td>
                  <td colSpan={14}></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      );
};

export default LaporanStuffing;